import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { stringify } from 'qs';

import { notifyError } from '@/shared/lib';

import { PayloadValidationError } from './errors';
import { Api, QueryParamsType } from './generated';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60 * 1000,
    },
  },
  queryCache: new QueryCache({
    //
  }),
  mutationCache: new MutationCache({
    onError(e) {
      if (e instanceof Response) {
        // TODO: fix types
        const errorResponse = e as any;

        if (e.status === 400) {
          throw new PayloadValidationError(errorResponse.error.message, errorResponse.error.errors);
        }

        notifyError(errorResponse.error.message);
        return;
      }

      notifyError(e.message);
    },
  }),
});

class EnhancedApiClient<T> extends Api<T> {
  protected toQueryString(rawQuery?: QueryParamsType) {
    return rawQuery ? stringify(rawQuery) : '';
  }
}

export const api = new EnhancedApiClient();
