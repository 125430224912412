import { api, Groups } from '@/shared/api';
import { defineQuery } from '@/shared/lib';

export const groupQueryKeyFactory = {
  all: () => ['groups'] as const,
  list: () => [...groupQueryKeyFactory.all(), 'list'] as const,
  listByPayload: (p: Groups.GetGroups.RequestQuery = {}) => [...groupQueryKeyFactory.list(), p] as const,
  single: (id: string) => [...groupQueryKeyFactory.all(), 'single', id] as const,
};

// TODO: consider splitting this onto two queries: groupsQuery and assignableGroupsQuery
export const [useGroupsQuery, groupsQuery] = defineQuery((p: Groups.GetGroups.RequestQuery = {}) => ({
  queryKey: groupQueryKeyFactory.listByPayload(p),
  queryFn: () => api.groups.getGroups(p),
}));

export const [useGroupQuery] = defineQuery((id: string) => ({
  queryKey: groupQueryKeyFactory.single(id),
  queryFn: () => api.groups.getGroup(id),
}));
