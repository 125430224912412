/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CurrentUserDto {
  permissions: string[];
  id: string;
  name: string;
  email: string;
  isActive: boolean;
  isSystem: boolean;
}

export interface EmailAuthPayloadDto {
  email: string;
}

export interface EmailAuthResponseDto {
  error: string | null;
}

export interface WorkspaceDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  title: string;
  slug: string;
  description: string | null;
}

export interface WorkspaceCountDtoPages {
  pages: number;
}

export interface WorkspaceDtoWithPagesCount {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  title: string;
  slug: string;
  description: string | null;
  _count: WorkspaceCountDtoPages;
}

export interface CreateWorkspaceDto {
  /**
   * @minLength 3
   * @maxLength 60
   */
  title: string;
  /** @maxLength 255 */
  description: string | null;
  /**
   * @minLength 3
   * @maxLength 100
   * @pattern /^[\w-]*$/
   */
  slug: string;
}

export interface UpdateWorkspaceDto {
  /**
   * @minLength 3
   * @maxLength 60
   */
  title: string;
  /** @maxLength 255 */
  description: string | null;
}

export interface CursorPaginatedResponseDto {
  total: number;
  cursor: string | null;
}

export interface TagCountDtoPages {
  pages: number;
}

export interface TagDtoWithPagesCount {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  _count: TagCountDtoPages;
  id: string;
  name: string;
}

export interface PaginationDto {
  cursor: string | null;
  /**
   * @min 1
   * @max 100
   */
  size: number;
}

export interface TagListSortingDto {
  field: "name" | "createdAt" | "pagesCount";
  direction: "asc" | "desc";
}

export interface TagListFilterDto {
  search?: string;
}

export interface TagDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  name: string;
}

export interface TagDataDto {
  /**
   * @minLength 3
   * @maxLength 30
   */
  name: string;
}

export interface GroupAvailableActionsDto {
  canDelete: boolean;
  canChangePermissions: boolean;
}

export interface GroupRelationsCountDto {
  users: number;
}

export interface GroupDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  name: string;
  permissions: string[];
  isSystem: boolean;
  availableActions?: GroupAvailableActionsDto;
  _count?: GroupRelationsCountDto;
}

export type Permission =
  | "everything"
  | "read:workspaces"
  | "write:workspaces"
  | "delete:workspaces"
  | "read:pages"
  | "write:pages"
  | "delete:pages"
  | "manage:users"
  | "manage:tags";

export interface GroupDataDto {
  /**
   * @minLength 3
   * @maxLength 255
   */
  name: string;
  permissions: (
    | "everything"
    | "read:workspaces"
    | "write:workspaces"
    | "delete:workspaces"
    | "read:pages"
    | "write:pages"
    | "delete:pages"
    | "manage:users"
    | "manage:tags"
  )[];
}

export interface UserRelativesCountDto {
  createdPages: number;
  updatedPages: number;
}

export interface UserActionsDto {
  canDelete: boolean;
  canChangeStatus: boolean;
  canAssignGroups: boolean;
  canSendInvitation: boolean;
}

export interface UserMetaDto {
  isSystemOrCurrent: boolean;
}

export interface UserWithRelativesDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  lastSigninAt: string;
  groups: GroupDto[];
  _count: UserRelativesCountDto;
  id: string;
  email: string;
  name: string;
  isActive: boolean;
  isSystem: boolean;
  availableActions?: UserActionsDto;
  meta?: UserMetaDto;
}

export interface UserDataDto {
  email: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  name: string;
  groups: string[];
}

export interface UserDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  lastSigninAt: string;
  id: string;
  email: string;
  name: string;
  isActive: boolean;
  isSystem: boolean;
  availableActions?: UserActionsDto;
  meta?: UserMetaDto;
}

export interface PageWithUpdatedBy {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  isDraft: boolean;
  title: string;
  description: string | null;
  content: string;
  textContent: string;
  createdById: string;
  updatedById: string;
  workspaceId: string;
  parentId: string | null;
  previousSiblingId: string | null;
  updatedBy: UserDto;
}

export interface PageRelationsCoundPartialDtoChildren {
  children: number;
}

export interface ChildPageDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  _count: PageRelationsCoundPartialDtoChildren;
  id: string;
  title: string;
  isDraft: boolean;
  parentId: string | null;
  previousSiblingId: string | null;
}

export interface PagePartialDtoId {
  id: string;
}

export interface TagPartialDtoName {
  name: string;
}

export interface WorkspacePartialDtoTitle {
  title: string;
}

export interface PageWithCommonRelations {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  isDraft: boolean;
  title: string;
  description: string | null;
  content: string;
  textContent: string;
  createdById: string;
  updatedById: string;
  workspaceId: string;
  parentId: string | null;
  previousSiblingId: string | null;
  createdBy: UserDto;
  updatedBy: UserDto;
  children: PagePartialDtoId[];
  tags: TagPartialDtoName[];
  workspace: WorkspacePartialDtoTitle;
}

export interface PageSummaryDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  versionDate: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  id: string;
  pageId: string;
  content: string;
}

export interface ParentPageDto {
  id: string;
  title: string;
}

export interface CreatePageDto {
  parentId: string | null;
  workspaceSlug: string;
}

export interface WorkspacePartialDtoSlug {
  slug: string;
}

export interface PageWithWorkspaceSlug {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  workspace: WorkspacePartialDtoSlug;
  id: string;
  isDraft: boolean;
  title: string;
  description: string | null;
  content: string;
  textContent: string;
  createdById: string;
  updatedById: string;
  workspaceId: string;
  parentId: string | null;
  previousSiblingId: string | null;
}

export interface UpdateDraftPageDto {
  /** @maxLength 60 */
  title: string;
  /** @maxLength 255 */
  description: string | null;
  content: string;
  tags: string[];
}

export interface PageDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  id: string;
  isDraft: boolean;
  title: string;
  description: string | null;
  content: string;
  textContent: string;
  createdById: string;
  updatedById: string;
  workspaceId: string;
  parentId: string | null;
  previousSiblingId: string | null;
}

export interface MovePageDto {
  parentId: string | null;
  previousSiblingId: string | null;
}

export interface PagesSearchResultDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  updatedAt: string;
  headline: string;
  workspaceSlug: string;
  workspaceTitle: string;
  updatedBy: string;
  total: number;
  id: string;
  title: string;
  description: string | null;
}

export interface AskAiAnswerDto {
  answer: string;
}

export interface SetupInfoDto {
  isComplete: boolean;
}

export interface CompleteSetupDto {
  email: string;
  /**
   * @minLength 2
   * @maxLength 50
   */
  name: string;
}

export interface PageAttachmentDto {
  /**
   * @format date-time
   * @example "2020-01-01T00:00:00.000Z"
   */
  createdAt: string;
  id: string;
  pageId: string;
  name: string;
}

export interface FileUploadDto {
  /** @format binary */
  file: File;
}

export interface SummarizationCompletedDto {
  taskId: string;
  content: string;
}

export namespace Metrics {
  /**
   * No description
   * @name Index
   * @request GET:/metrics
   */
  export namespace Index {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Auth {
  /**
   * No description
   * @tags Auth
   * @name GetMe
   * @request GET:/api/auth/me
   */
  export namespace GetMe {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CurrentUserDto;
  }

  /**
   * No description
   * @tags Auth
   * @name Logout
   * @request POST:/api/auth/logout
   */
  export namespace Logout {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Auth
   * @name GoogleAuth
   * @request GET:/api/auth/google
   */
  export namespace GoogleAuth {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Auth
   * @name GoogleAuthRedirect
   * @request GET:/api/auth/callback/google
   */
  export namespace GoogleAuthRedirect {
    export type RequestParams = {};
    export type RequestQuery = {
      redirect_uri: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Auth
   * @name EmailAuth
   * @request POST:/api/auth/email
   */
  export namespace EmailAuth {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = EmailAuthPayloadDto;
    export type RequestHeaders = {};
    export type ResponseBody = EmailAuthResponseDto;
  }

  /**
   * No description
   * @tags Auth
   * @name EmailAuthRedirect
   * @request GET:/api/auth/callback/email
   */
  export namespace EmailAuthRedirect {
    export type RequestParams = {};
    export type RequestQuery = {
      token: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Workspaces {
  /**
   * No description
   * @tags Workspaces
   * @name GetWorkspaces
   * @request GET:/api/workspaces
   */
  export namespace GetWorkspaces {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = WorkspaceDto[];
  }

  /**
   * No description
   * @tags Workspaces
   * @name CreateWorkspace
   * @request POST:/api/workspaces
   */
  export namespace CreateWorkspace {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateWorkspaceDto;
    export type RequestHeaders = {};
    export type ResponseBody = WorkspaceDto;
  }

  /**
   * No description
   * @tags Workspaces
   * @name GetWorkspace
   * @request GET:/api/workspaces/{slug}
   */
  export namespace GetWorkspace {
    export type RequestParams = {
      slug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = WorkspaceDtoWithPagesCount;
  }

  /**
   * No description
   * @tags Workspaces
   * @name UpdateWorkspace
   * @request PUT:/api/workspaces/{id}
   */
  export namespace UpdateWorkspace {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateWorkspaceDto;
    export type RequestHeaders = {};
    export type ResponseBody = WorkspaceDto;
  }

  /**
   * No description
   * @tags Workspaces
   * @name DeleteWorkspace
   * @request DELETE:/api/workspaces/{id}
   */
  export namespace DeleteWorkspace {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = WorkspaceDto;
  }
}

export namespace Tags {
  /**
   * No description
   * @tags Tags
   * @name GetTags
   * @request GET:/api/tags
   */
  export namespace GetTags {
    export type RequestParams = {};
    export type RequestQuery = {
      pagination: PaginationDto;
      sort?: TagListSortingDto;
      filter?: TagListFilterDto;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CursorPaginatedResponseDto & {
      data: TagDtoWithPagesCount[];
    };
  }

  /**
   * No description
   * @tags Tags
   * @name CreateTag
   * @request POST:/api/tags
   */
  export namespace CreateTag {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = TagDataDto;
    export type RequestHeaders = {};
    export type ResponseBody = TagDto;
  }

  /**
   * No description
   * @tags Tags
   * @name GetTag
   * @request GET:/api/tags/{id}
   */
  export namespace GetTag {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TagDto;
  }

  /**
   * No description
   * @tags Tags
   * @name UpdateTag
   * @request PUT:/api/tags/{id}
   */
  export namespace UpdateTag {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = TagDataDto;
    export type RequestHeaders = {};
    export type ResponseBody = TagDto;
  }

  /**
   * No description
   * @tags Tags
   * @name DeleteTag
   * @request DELETE:/api/tags/{id}
   */
  export namespace DeleteTag {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TagDto;
  }
}

export namespace Groups {
  /**
   * No description
   * @tags Groups
   * @name GetGroups
   * @request GET:/api/groups
   */
  export namespace GetGroups {
    export type RequestParams = {};
    export type RequestQuery = {
      includeSystem?: boolean;
      countUsers?: boolean;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GroupDto[];
  }

  /**
   * No description
   * @tags Groups
   * @name CreateGroup
   * @request POST:/api/groups
   */
  export namespace CreateGroup {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = GroupDataDto;
    export type RequestHeaders = {};
    export type ResponseBody = GroupDto;
  }

  /**
   * No description
   * @tags Groups
   * @name GetGroup
   * @request GET:/api/groups/{id}
   */
  export namespace GetGroup {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GroupDto;
  }

  /**
   * No description
   * @tags Groups
   * @name UpdateGroup
   * @request PUT:/api/groups/{id}
   */
  export namespace UpdateGroup {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = GroupDataDto;
    export type RequestHeaders = {};
    export type ResponseBody = GroupDto;
  }

  /**
   * No description
   * @tags Groups
   * @name DeleteGroup
   * @request DELETE:/api/groups/{id}
   */
  export namespace DeleteGroup {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = GroupDto;
  }
}

export namespace Users {
  /**
   * No description
   * @tags Users
   * @name GetUsers
   * @request GET:/api/users
   */
  export namespace GetUsers {
    export type RequestParams = {};
    export type RequestQuery = {
      group?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserWithRelativesDto[];
  }

  /**
   * No description
   * @tags Users
   * @name CreateUser
   * @request POST:/api/users
   */
  export namespace CreateUser {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = UserDataDto;
    export type RequestHeaders = {};
    export type ResponseBody = UserDto;
  }

  /**
   * No description
   * @tags Users
   * @name GetUser
   * @request GET:/api/users/{id}
   */
  export namespace GetUser {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserWithRelativesDto;
  }

  /**
   * No description
   * @tags Users
   * @name UpdateUser
   * @request PUT:/api/users/{id}
   */
  export namespace UpdateUser {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UserDataDto;
    export type RequestHeaders = {};
    export type ResponseBody = UserDto;
  }

  /**
   * No description
   * @tags Users
   * @name DeleteUser
   * @request DELETE:/api/users/{id}
   */
  export namespace DeleteUser {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserDto;
  }

  /**
   * No description
   * @tags Users
   * @name SendInvitation
   * @request POST:/api/users/{id}/send-invitation
   */
  export namespace SendInvitation {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }

  /**
   * No description
   * @tags Users
   * @name ActivateUser
   * @request POST:/api/users/{id}/activate
   */
  export namespace ActivateUser {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserDto;
  }

  /**
   * No description
   * @tags Users
   * @name DeactivateUser
   * @request POST:/api/users/{id}/deactivate
   */
  export namespace DeactivateUser {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserDto;
  }
}

export namespace Pages {
  /**
   * No description
   * @tags Pages
   * @name GetLatestUpdatedPages
   * @request GET:/api/pages/latest
   */
  export namespace GetLatestUpdatedPages {
    export type RequestParams = {};
    export type RequestQuery = {
      workspaceSlug: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageWithUpdatedBy[];
  }

  /**
   * No description
   * @tags Pages
   * @name GetChildrenPages
   * @request GET:/api/pages/children
   */
  export namespace GetChildrenPages {
    export type RequestParams = {};
    export type RequestQuery = {
      workspaceSlug: string;
      parentId: string | null;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ChildPageDto[];
  }

  /**
   * No description
   * @tags Pages
   * @name GetPage
   * @request GET:/api/pages/{id}
   */
  export namespace GetPage {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageWithCommonRelations;
  }

  /**
   * No description
   * @tags Pages
   * @name UpdatePage
   * @request PUT:/api/pages/{id}
   */
  export namespace UpdatePage {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = UpdateDraftPageDto;
    export type RequestHeaders = {};
    export type ResponseBody = PageDto;
  }

  /**
   * No description
   * @tags Pages
   * @name DeletePage
   * @request DELETE:/api/pages/{id}
   */
  export namespace DeletePage {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageWithWorkspaceSlug;
  }

  /**
   * No description
   * @tags Pages
   * @name GetPageSummary
   * @request GET:/api/pages/{id}/summary
   */
  export namespace GetPageSummary {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageSummaryDto;
  }

  /**
   * No description
   * @tags Pages
   * @name GetParentPages
   * @request GET:/api/pages/{id}/parents
   */
  export namespace GetParentPages {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = ParentPageDto[];
  }

  /**
   * No description
   * @tags Pages
   * @name CreatePage
   * @request POST:/api/pages
   */
  export namespace CreatePage {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreatePageDto;
    export type RequestHeaders = {};
    export type ResponseBody = PageWithWorkspaceSlug;
  }

  /**
   * No description
   * @tags Pages
   * @name PublishPage
   * @request PATCH:/api/pages/{id}/publish
   */
  export namespace PublishPage {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageDto;
  }

  /**
   * No description
   * @tags Pages
   * @name MovePage
   * @request PATCH:/api/pages/{id}/move
   */
  export namespace MovePage {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = MovePageDto;
    export type RequestHeaders = {};
    export type ResponseBody = PageDto;
  }
}

export namespace Search {
  /**
   * No description
   * @tags Search
   * @name SearchPages
   * @request GET:/api/search/pages
   */
  export namespace SearchPages {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @minLength 3 */
      query: string;
      workspaceSlug?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PagesSearchResultDto[];
  }
}

export namespace Ai {
  /**
   * No description
   * @tags AI
   * @name Ask
   * @request GET:/api/ai/ask
   */
  export namespace Ask {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @minLength 1 */
      prompt: string;
      workspaceId: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = AskAiAnswerDto;
  }

  /**
   * No description
   * @tags AI
   * @name RebuildIndex
   * @request POST:/api/ai/reindex/{workspaceSlug}
   */
  export namespace RebuildIndex {
    export type RequestParams = {
      workspaceSlug: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Setup {
  /**
   * No description
   * @tags Setup
   * @name IsComplete
   * @request GET:/api/setup
   */
  export namespace IsComplete {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SetupInfoDto;
  }

  /**
   * No description
   * @tags Setup
   * @name CompleteSetup
   * @request POST:/api/setup
   */
  export namespace CompleteSetup {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CompleteSetupDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Pdf {
  /**
   * No description
   * @tags PDF
   * @name ExportPageToPdf
   * @request GET:/api/pdf/page/{id}
   */
  export namespace ExportPageToPdf {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace PageAttachments {
  /**
   * No description
   * @tags Page Attachments
   * @name Get
   * @request POST:/api/page-attachments/{id}
   */
  export namespace Get {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = PageAttachmentDto;
  }

  /**
   * No description
   * @tags Page Attachments
   * @name Upload
   * @request POST:/api/pages/{pageId}/attachments
   */
  export namespace Upload {
    export type RequestParams = {
      pageId: string;
    };
    export type RequestQuery = {};
    export type RequestBody = FileUploadDto;
    export type RequestHeaders = {};
    export type ResponseBody = PageAttachmentDto;
  }

  /**
   * No description
   * @tags Page Attachments
   * @name GetFile
   * @request GET:/api/file/attachment/{id}
   */
  export namespace GetFile {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = File;
  }
}

export namespace Webhooks {
  /**
   * No description
   * @tags Webhooks
   * @name SummarizationCompleted
   * @request POST:/webhook/summarization
   */
  export namespace SummarizationCompleted {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = SummarizationCompletedDto;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data.data;
    });
  };
}

/**
 * @title Stratcom IDP Companion
 * @version 1.0.0
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  metrics = {
    /**
     * No description
     *
     * @name Index
     * @request GET:/metrics
     */
    index: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/metrics`,
        method: "GET",
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name GetMe
     * @request GET:/api/auth/me
     */
    getMe: (params: RequestParams = {}) =>
      this.request<CurrentUserDto, any>({
        path: `/api/auth/me`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name Logout
     * @request POST:/api/auth/logout
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/logout`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GoogleAuth
     * @request GET:/api/auth/google
     */
    googleAuth: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/auth/google`,
        method: "GET",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name GoogleAuthRedirect
     * @request GET:/api/auth/callback/google
     */
    googleAuthRedirect: (
      query: {
        redirect_uri: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/auth/callback/google`,
        method: "GET",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name EmailAuth
     * @request POST:/api/auth/email
     */
    emailAuth: (data: EmailAuthPayloadDto, params: RequestParams = {}) =>
      this.request<EmailAuthResponseDto, any>({
        path: `/api/auth/email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name EmailAuthRedirect
     * @request GET:/api/auth/callback/email
     */
    emailAuthRedirect: (
      query: {
        token: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/auth/callback/email`,
        method: "GET",
        query: query,
        ...params,
      }),
  };
  workspaces = {
    /**
     * No description
     *
     * @tags Workspaces
     * @name GetWorkspaces
     * @request GET:/api/workspaces
     */
    getWorkspaces: (params: RequestParams = {}) =>
      this.request<WorkspaceDto[], any>({
        path: `/api/workspaces`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name CreateWorkspace
     * @request POST:/api/workspaces
     */
    createWorkspace: (data: CreateWorkspaceDto, params: RequestParams = {}) =>
      this.request<WorkspaceDto, any>({
        path: `/api/workspaces`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name GetWorkspace
     * @request GET:/api/workspaces/{slug}
     */
    getWorkspace: (slug: string, params: RequestParams = {}) =>
      this.request<WorkspaceDtoWithPagesCount, any>({
        path: `/api/workspaces/${slug}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name UpdateWorkspace
     * @request PUT:/api/workspaces/{id}
     */
    updateWorkspace: (id: string, data: UpdateWorkspaceDto, params: RequestParams = {}) =>
      this.request<WorkspaceDto, any>({
        path: `/api/workspaces/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Workspaces
     * @name DeleteWorkspace
     * @request DELETE:/api/workspaces/{id}
     */
    deleteWorkspace: (id: string, params: RequestParams = {}) =>
      this.request<WorkspaceDto, any>({
        path: `/api/workspaces/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  tags = {
    /**
     * No description
     *
     * @tags Tags
     * @name GetTags
     * @request GET:/api/tags
     */
    getTags: (
      query: {
        pagination: PaginationDto;
        sort?: TagListSortingDto;
        filter?: TagListFilterDto;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        CursorPaginatedResponseDto & {
          data: TagDtoWithPagesCount[];
        },
        any
      >({
        path: `/api/tags`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name CreateTag
     * @request POST:/api/tags
     */
    createTag: (data: TagDataDto, params: RequestParams = {}) =>
      this.request<TagDto, any>({
        path: `/api/tags`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name GetTag
     * @request GET:/api/tags/{id}
     */
    getTag: (id: string, params: RequestParams = {}) =>
      this.request<TagDto, any>({
        path: `/api/tags/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name UpdateTag
     * @request PUT:/api/tags/{id}
     */
    updateTag: (id: string, data: TagDataDto, params: RequestParams = {}) =>
      this.request<TagDto, any>({
        path: `/api/tags/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name DeleteTag
     * @request DELETE:/api/tags/{id}
     */
    deleteTag: (id: string, params: RequestParams = {}) =>
      this.request<TagDto, any>({
        path: `/api/tags/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  groups = {
    /**
     * No description
     *
     * @tags Groups
     * @name GetGroups
     * @request GET:/api/groups
     */
    getGroups: (
      query?: {
        includeSystem?: boolean;
        countUsers?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GroupDto[], any>({
        path: `/api/groups`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name CreateGroup
     * @request POST:/api/groups
     */
    createGroup: (data: GroupDataDto, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/api/groups`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name GetGroup
     * @request GET:/api/groups/{id}
     */
    getGroup: (id: string, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/api/groups/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name UpdateGroup
     * @request PUT:/api/groups/{id}
     */
    updateGroup: (id: string, data: GroupDataDto, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/api/groups/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Groups
     * @name DeleteGroup
     * @request DELETE:/api/groups/{id}
     */
    deleteGroup: (id: string, params: RequestParams = {}) =>
      this.request<GroupDto, any>({
        path: `/api/groups/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name GetUsers
     * @request GET:/api/users
     */
    getUsers: (
      query?: {
        group?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserWithRelativesDto[], any>({
        path: `/api/users`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name CreateUser
     * @request POST:/api/users
     */
    createUser: (data: UserDataDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/users`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name GetUser
     * @request GET:/api/users/{id}
     */
    getUser: (id: string, params: RequestParams = {}) =>
      this.request<UserWithRelativesDto, any>({
        path: `/api/users/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UpdateUser
     * @request PUT:/api/users/{id}
     */
    updateUser: (id: string, data: UserDataDto, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/users/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name DeleteUser
     * @request DELETE:/api/users/{id}
     */
    deleteUser: (id: string, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/users/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name SendInvitation
     * @request POST:/api/users/{id}/send-invitation
     */
    sendInvitation: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/users/${id}/send-invitation`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name ActivateUser
     * @request POST:/api/users/{id}/activate
     */
    activateUser: (id: string, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/users/${id}/activate`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name DeactivateUser
     * @request POST:/api/users/{id}/deactivate
     */
    deactivateUser: (id: string, params: RequestParams = {}) =>
      this.request<UserDto, any>({
        path: `/api/users/${id}/deactivate`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  pages = {
    /**
     * No description
     *
     * @tags Pages
     * @name GetLatestUpdatedPages
     * @request GET:/api/pages/latest
     */
    getLatestUpdatedPages: (
      query: {
        workspaceSlug: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PageWithUpdatedBy[], any>({
        path: `/api/pages/latest`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetChildrenPages
     * @request GET:/api/pages/children
     */
    getChildrenPages: (
      query: {
        workspaceSlug: string;
        parentId: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<ChildPageDto[], any>({
        path: `/api/pages/children`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetPage
     * @request GET:/api/pages/{id}
     */
    getPage: (id: string, params: RequestParams = {}) =>
      this.request<PageWithCommonRelations, any>({
        path: `/api/pages/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name UpdatePage
     * @request PUT:/api/pages/{id}
     */
    updatePage: (id: string, data: UpdateDraftPageDto, params: RequestParams = {}) =>
      this.request<PageDto, any>({
        path: `/api/pages/${id}`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name DeletePage
     * @request DELETE:/api/pages/{id}
     */
    deletePage: (id: string, params: RequestParams = {}) =>
      this.request<PageWithWorkspaceSlug, any>({
        path: `/api/pages/${id}`,
        method: "DELETE",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetPageSummary
     * @request GET:/api/pages/{id}/summary
     */
    getPageSummary: (id: string, params: RequestParams = {}) =>
      this.request<PageSummaryDto, any>({
        path: `/api/pages/${id}/summary`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name GetParentPages
     * @request GET:/api/pages/{id}/parents
     */
    getParentPages: (id: string, params: RequestParams = {}) =>
      this.request<ParentPageDto[], any>({
        path: `/api/pages/${id}/parents`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name CreatePage
     * @request POST:/api/pages
     */
    createPage: (data: CreatePageDto, params: RequestParams = {}) =>
      this.request<PageWithWorkspaceSlug, any>({
        path: `/api/pages`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name PublishPage
     * @request PATCH:/api/pages/{id}/publish
     */
    publishPage: (id: string, params: RequestParams = {}) =>
      this.request<PageDto, any>({
        path: `/api/pages/${id}/publish`,
        method: "PATCH",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Pages
     * @name MovePage
     * @request PATCH:/api/pages/{id}/move
     */
    movePage: (id: string, data: MovePageDto, params: RequestParams = {}) =>
      this.request<PageDto, any>({
        path: `/api/pages/${id}/move`,
        method: "PATCH",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  search = {
    /**
     * No description
     *
     * @tags Search
     * @name SearchPages
     * @request GET:/api/search/pages
     */
    searchPages: (
      query: {
        /** @minLength 3 */
        query: string;
        workspaceSlug?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PagesSearchResultDto[], any>({
        path: `/api/search/pages`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  ai = {
    /**
     * No description
     *
     * @tags AI
     * @name Ask
     * @request GET:/api/ai/ask
     */
    ask: (
      query: {
        /** @minLength 1 */
        prompt: string;
        workspaceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AskAiAnswerDto, any>({
        path: `/api/ai/ask`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AI
     * @name RebuildIndex
     * @request POST:/api/ai/reindex/{workspaceSlug}
     */
    rebuildIndex: (workspaceSlug: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/ai/reindex/${workspaceSlug}`,
        method: "POST",
        ...params,
      }),
  };
  setup = {
    /**
     * No description
     *
     * @tags Setup
     * @name IsComplete
     * @request GET:/api/setup
     */
    isComplete: (params: RequestParams = {}) =>
      this.request<SetupInfoDto, any>({
        path: `/api/setup`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Setup
     * @name CompleteSetup
     * @request POST:/api/setup
     */
    completeSetup: (data: CompleteSetupDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/setup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  pdf = {
    /**
     * No description
     *
     * @tags PDF
     * @name ExportPageToPdf
     * @request GET:/api/pdf/page/{id}
     */
    exportPageToPdf: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/pdf/page/${id}`,
        method: "GET",
        ...params,
      }),
  };
  pageAttachments = {
    /**
     * No description
     *
     * @tags Page Attachments
     * @name Get
     * @request POST:/api/page-attachments/{id}
     */
    get: (id: string, params: RequestParams = {}) =>
      this.request<PageAttachmentDto, any>({
        path: `/api/page-attachments/${id}`,
        method: "POST",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Page Attachments
     * @name Upload
     * @request POST:/api/pages/{pageId}/attachments
     */
    upload: (pageId: string, data: FileUploadDto, params: RequestParams = {}) =>
      this.request<PageAttachmentDto, any>({
        path: `/api/pages/${pageId}/attachments`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Page Attachments
     * @name GetFile
     * @request GET:/api/file/attachment/{id}
     */
    getFile: (id: string, params: RequestParams = {}) =>
      this.request<File, any>({
        path: `/api/file/attachment/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  webhooks = {
    /**
     * No description
     *
     * @tags Webhooks
     * @name SummarizationCompleted
     * @request POST:/webhook/summarization
     */
    summarizationCompleted: (data: SummarizationCompletedDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/webhook/summarization`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
}
