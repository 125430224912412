import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';

import { useCurrentUser } from '@/shared/session';

export const Route = createFileRoute('/_protected')({
  component: Component,
});

function Component() {
  const user = useCurrentUser();
  if (!user || !user.isActive) {
    return <Navigate to="/auth/signin" replace />;
  }

  return <Outlet />;
}
