import { createFileRoute, notFound } from '@tanstack/react-router';
import { z } from 'zod';

import { Permission } from '@/shared/const';
import { hasPermission } from '@/shared/session';

export const Route = createFileRoute('/_protected/admin/tags')({
  validateSearch: z.object({
    search: z.string().optional(),
  }),
  async beforeLoad() {
    const canManageTags = await hasPermission(Permission.ManageTags);
    if (!canManageTags)
      throw notFound();
  },
});
