import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tagQueryKeyFactory } from '@/entities/tag';

import { api, TagDataDto, Tags } from '@/shared/api';
import { Permission } from '@/shared/const';
import { notifySuccess } from '@/shared/lib';
import { usePermission } from '@/shared/session';

import { EDIT_TAG_MODAL_KEY } from './const';

export function useEditTag() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const canEditTag = usePermission(Permission.ManageTags);

  const openEditModal = (tagId: string) => openContextModal({
    modal: EDIT_TAG_MODAL_KEY,
    title: 'Edit Tag',
    size: 'md',
    fullScreen: isMobile,
    radius: isMobile ? 0 : 'md',
    innerProps: {
      tagId,
    },
  });

  return {
    editTag: openEditModal,
    canEditTag,
  };
}

export function useUpdateTagMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Tags.UpdateTag.RequestParams & TagDataDto) => api.tags.updateTag(payload.id, payload),
    async onSuccess({ id }) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: tagQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: tagQueryKeyFactory.single(id) }),
      ]);

      notifySuccess('Tag has been updated');
    },
  });
}
