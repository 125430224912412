import { useEffect } from 'react';

import { Notifications } from '@mantine/notifications';

import { captureException } from '@sentry/react';
import { createRootRoute, ErrorComponent as BaseErrorComponent, ErrorComponentProps, Outlet } from '@tanstack/react-router';

import { ModalsProvider } from '@/app/modals';

import { ProgressBar } from '@/widgets/progress-bar';

export const Route = createRootRoute({
  component: Component,
  errorComponent: ErrorComponent,
});

function Component() {
  return (
    <ProgressBar>
      <Notifications zIndex={10000} />

      <ModalsProvider>
        <Outlet />
      </ModalsProvider>
    </ProgressBar>
  );
}

function ErrorComponent(props: ErrorComponentProps) {
  const { error } = props;

  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <BaseErrorComponent {...props} />
  );
}
