import { useEffect, useMemo } from 'react';

import { Button, Group, Input, MultiSelect, Skeleton, Stack, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useGroupsQuery } from '@/entities/group';
import { useUserQuery } from '@/entities/user';

import { useAsyncForm } from '@/shared/hooks';

import { useUpdateUserMutation } from '../hooks';

type Props = ContextModalProps<{
  userId: string;
}>;

export function EditUserModal({ id, context, innerProps: { userId } }: Props) {
  const { data: user, isLoading: isLoadingUser } = useUserQuery(userId);
  const { data: groups = [], isLoading: isLoadingGroups } = useGroupsQuery();
  const groupOptions = useMemo(() => groups.map(group => ({
    value: String(group.id),
    label: group.name,
  })), [groups]);

  const form = useAsyncForm({
    initialValues: {
      id: userId,
      email: '',
      name: '',
      groups: [] as string[],
    },
  });

  useEffect(() => {
    if (!user)
      return;

    const preparedValues = {
      id: userId,
      email: user.email,
      name: user.name,
      groups: user.groups.map(i => i.id),
    };

    form.setValues(preparedValues);
    form.resetDirty(preparedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { mutateAsync, isPending } = useUpdateUserMutation();
  const handleSave = async (values: typeof form.values) => {
    await mutateAsync(values);
    context.closeModal(id);
  };

  return (
    <form onSubmit={form.onSubmit(handleSave)}>
      <Stack>
        <Input.Wrapper withAsterisk label="Name">
          <Skeleton visible={isLoadingUser}>
            <TextInput data-autofocus {...form.getInputProps('name')} />
          </Skeleton>
        </Input.Wrapper>

        <Input.Wrapper withAsterisk label="Email">
          <Skeleton visible={isLoadingUser}>
            <TextInput {...form.getInputProps('email')} />
          </Skeleton>
        </Input.Wrapper>

        <Input.Wrapper withAsterisk label="Groups">
          <Skeleton visible={isLoadingUser || isLoadingGroups}>
            <MultiSelect
              checkIconPosition="right"
              data={groupOptions}
              {...form.getInputProps('groups')}
              {...(!user?.availableActions?.canAssignGroups && {
                disabled: true,
                description: 'You aren\'t authorized to change this user\'s groups',
              })}
            />
          </Skeleton>
        </Input.Wrapper>
      </Stack>

      <Group mt="xl" justify="end">
        <Button type="submit" disabled={isLoadingUser || !form.isDirty()} loading={isPending}>
          Save
        </Button>

        <Button variant="default" onClick={() => context.closeModal(id, true)}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
