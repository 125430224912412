import { Button, Group, Stack, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useAsyncForm } from '@/shared/hooks';

import { useCreateTagMutation } from './hooks';

export function NewTagModal({ id, context }: ContextModalProps) {
  const form = useAsyncForm({
    initialValues: {
      name: '',
    },
  });

  const close = () => context.closeModal(id);
  const { mutateAsync, isPending } = useCreateTagMutation();
  const submit = async (values: typeof form.values) => {
    await mutateAsync(values);
    close();
  };

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack>
        <TextInput
          data-autofocus
          withAsterisk
          label="Name"
          {...form.getInputProps('name')}
        />
      </Stack>

      <Group mt="xl" justify="end">
        <Button type="submit" loading={isPending}>
          Create
        </Button>

        <Button variant="default" onClick={close}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
