import { NotificationData, notifications } from '@mantine/notifications';

function createNotifierWithDefaults(defaultProps: Partial<NotificationData>) {
  return (props: NotificationData | string) => {
    const isOnlyMessage = typeof props === 'string';

    notifications.show({
      ...defaultProps,
      ...(isOnlyMessage ? { message: props } : props),
    });
  };
}

export const notifySuccess = createNotifierWithDefaults({
  title: 'Done',
  color: 'green',
});

export const notifyError = createNotifierWithDefaults({
  title: 'Error',
  color: 'red',
  autoClose: 10_000,
});

export const notifyInfo = createNotifierWithDefaults({
  color: 'blue',
});
