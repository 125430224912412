'use client';

import { forwardRef } from 'react';

import { Box, BoxProps, rem } from '@mantine/core';
import { Icon as IconType, TablerIconsProps } from '@tabler/icons-react';

export type IconProps =
  & Omit<TablerIconsProps, 'size'>
  & Omit<BoxProps, 'w' | 'h' | 'component'>
  & {
    src: IconType;
    size?: number;
  };

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ src, size = 16, stroke = 1.5, style, ...props }, ref) => (
    <Box
      // @ts-expect-error LegacyRef is not compatible with Box, but types are correct
      ref={ref}
      component={src}
      stroke={stroke}
      style={{
        width: rem(size),
        height: rem(size),
        ...style,
      }}
      {...props}
    />
  ),
);

Icon.displayName = 'Icon';
