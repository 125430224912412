export enum Permission {
  Everything = 'everything',
  ReadWorkspaces = 'read:workspaces',
  WriteWorkspaces = 'write:workspaces',
  DeleteWorkspaces = 'delete:workspaces',
  ReadPages = 'read:pages',
  WritePages = 'write:pages',
  DeletePages = 'delete:pages',
  ManageUsers = 'manage:users',
  ManageTags = 'manage:tags',
}

export const ADMIN_PERMESSIONS = [
  Permission.ManageUsers,
  Permission.ManageTags,
];

export const PERMISSIONS = [
  {
    name: 'Workspaces',
    permissions: [
      { key: Permission.ReadWorkspaces, name: 'Read' },
      { key: Permission.WriteWorkspaces, name: 'Write' },
      { key: Permission.DeleteWorkspaces, name: 'Delete' },
    ],
  },
  {
    name: 'Pages',
    permissions: [
      { key: Permission.ReadPages, name: 'Read' },
      { key: Permission.WritePages, name: 'Write' },
      { key: Permission.DeletePages, name: 'Delete' },
    ],
  },
  {
    name: 'Administration',
    permissions: [
      { key: Permission.ManageUsers, name: 'Manage users' },
      { key: Permission.ManageTags, name: 'Manage tags' },
    ],
  },
];
