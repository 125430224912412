/*
  This hook is a wrapper around @mantine/form useForm hook.
  It implements missing async validation functionality.

  For example, you can use it with schema, which validates uniqueness of some field in database through API.
 */

import { FormEvent } from 'react';

import { FormErrors, useForm, UseFormInput } from '@mantine/form';

import { PayloadValidationError } from '@/shared/api/errors';

type UseAsyncFormInput<V, T extends (values: V) => unknown> = Omit<UseFormInput<V, T>, 'validate'>;
type SubmitHandler<T> = (values: T, event: FormEvent<HTMLFormElement> | undefined) => void | Promise<void>;
type ErrorHandler<T> = (errors: FormErrors, values: T, event: FormEvent<HTMLFormElement> | undefined) => void;

export function useAsyncForm<
  Values extends Record<string, unknown> = Record<string, unknown>,
  TransformValues extends (
    values: Values
  ) => unknown = (values: Values) => Values,
>(
  options: UseAsyncFormInput<Values, TransformValues> = {},
  baseHook?: typeof useForm<Values, TransformValues>,
) {
  const form = (baseHook ?? useForm)(options);

  const onSubmitWithAsyncValidation = (
    submitHandler: SubmitHandler<ReturnType<TransformValues>>,
    errorHandler?: ErrorHandler<Values>,
  ) => async (event?: FormEvent<HTMLFormElement>) => {
    event?.preventDefault();

    const wrappedSubmitHandler: typeof submitHandler = async (...args) => {
      try {
        await submitHandler(...args);
      } catch (e) {
        if (!(e instanceof PayloadValidationError))
          throw e;

        const errors: FormErrors = {};

        e.errors.forEach((issue) => {
          errors[issue.path] = issue.message;
        });

        form.setErrors(errors);
        errorHandler?.(errors, form.values, event);
      }
    };

    form.onSubmit(wrappedSubmitHandler, errorHandler)(event);
  };

  return {
    ...form,
    onSubmit: onSubmitWithAsyncValidation,
  };
}
