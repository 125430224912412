import { useEffect } from 'react';

import { Button, Checkbox, Group, Input, SimpleGrid, Skeleton, Stack, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useGroupQuery } from '@/entities/group';

import { Permission, PERMISSIONS } from '@/shared/const';
import { useAsyncForm } from '@/shared/hooks';

import { useUpdateGroupMutation } from '../hooks';

type Props = ContextModalProps<{
  groupId: string;
}>;

export function EditGroupModal({ id, context, innerProps: { groupId } }: Props) {
  const { data: group, isLoading: isLoadingGroup } = useGroupQuery(groupId);

  const form = useAsyncForm({
    initialValues: {
      name: '',
      permissions: [] as Permission[],
    },
  });

  useEffect(() => {
    if (!group)
      return;

    const preparedValues = {
      name: group.name,
      permissions: group.permissions as Permission[],
    };

    form.setValues(preparedValues);
    form.resetDirty(preparedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const { mutateAsync, isPending } = useUpdateGroupMutation();
  const handleSave = async (values: typeof form.values) => {
    await mutateAsync({ id: groupId, ...values });
    context.closeModal(id);
  };

  return (
    <form onSubmit={form.onSubmit(handleSave)}>
      <Stack>
        <Input.Wrapper label="Name" withAsterisk>
          <Skeleton visible={isLoadingGroup}>
            <TextInput data-autofocus {...form.getInputProps('name')} />
          </Skeleton>
        </Input.Wrapper>

        {(isLoadingGroup || group?.availableActions?.canChangePermissions) && (
          <Checkbox.Group {...form.getInputProps('permissions')}>
            <SimpleGrid cols={2} verticalSpacing="lg" mb="sm">
              {PERMISSIONS.map(permissionsGroup => (
                <Input.Wrapper key={permissionsGroup.name} label={permissionsGroup.name}>
                  <Stack mt="xs">
                    {permissionsGroup.permissions.map(permission => (
                      <Checkbox
                        key={permission.key}
                        label={permission.name}
                        value={permission.key}
                        disabled={isLoadingGroup}
                      />
                    ))}
                  </Stack>
                </Input.Wrapper>
              ))}
            </SimpleGrid>
          </Checkbox.Group>
        )}
      </Stack>

      <Group mt="xl" justify="end">
        <Button type="submit" disabled={isLoadingGroup || !form.isDirty()} loading={isPending}>
          Save
        </Button>

        <Button variant="default" onClick={() => context.closeModal(id, true)}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
