import { Group, LoadingOverlay, Text } from '@mantine/core';
import { Dropzone, PDF_MIME_TYPE } from '@mantine/dropzone';
import { ContextModalProps } from '@mantine/modals';
import { IconFile, IconUpload, IconX } from '@tabler/icons-react';

import { PageAttachmentDto } from '@/shared/api';
import { notifyError } from '@/shared/lib';
import { Icon } from '@/shared/ui';

import { useUploadPageAttachmentMutation } from '../hooks';

export type UploadAttachmentModalProps = ContextModalProps<{
  pageId: string;
  onSubmit: (file: PageAttachmentDto) => Promise<void> | void;
  onCancel?: () => void;
}>;

const MAX_FILE_SIZE = 50 * 1024 ** 2;

export function UploadAttachmentModal({ id, context, innerProps }: UploadAttachmentModalProps) {
  const { pageId, onSubmit, onCancel } = innerProps;

  const close = () => {
    context.closeModal(id);
    onCancel?.();
  };

  const { mutateAsync, isPending } = useUploadPageAttachmentMutation();
  const submit = async (files: File[]) => {
    if (files[0].size > MAX_FILE_SIZE) {
      notifyError('File size should not exceed 50MB');
      return;
    }

    const uploadedFile = await mutateAsync({
      pageId,
      file: files[0],
    });

    await onSubmit(uploadedFile);
    close();
  };

  return (
    <div>
      <LoadingOverlay visible={isPending} />

      <Dropzone
        onDrop={submit}
        accept={PDF_MIME_TYPE}
      >
        <Group justify="center" gap="xl" mih={220} style={{ pointerEvents: 'none' }}>
          <Dropzone.Accept>
            <Icon src={IconUpload} size={52} c="blue" />
          </Dropzone.Accept>

          <Dropzone.Reject>
            <Icon src={IconX} size={52} c="red" />
          </Dropzone.Reject>

          <Dropzone.Idle>
            <Icon src={IconFile} size={52} c="dimmed" />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drag file here or click to select file
            </Text>

            <Text size="sm" c="dimmed" inline mt={8}>
              File should not exceed 10MB
            </Text>
          </div>
        </Group>
      </Dropzone>
    </div>
  );
}
