'use client';

import { Button, Group, Space, Stack, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useAwaitable } from '@/shared/hooks';

export type ConfirmationModalProps = ContextModalProps<{
  text: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => unknown | Promise<unknown>;
}>;

export const CONFIRMATION_MODAL_KEY = 'confirmation';

export function ConfirmationModal({ id, context, innerProps }: ConfirmationModalProps) {
  const { text, confirmButtonText = 'Confirm', cancelButtonText = 'Cancel', onConfirm } = innerProps;

  const [handleConfirm, isAwaiting] = useAwaitable(async () => {
    await onConfirm();
    context.closeModal(id);
  });

  return (
    <Stack gap="xs">
      <Text size="sm">{text}</Text>

      <Space h="md" />

      <Group gap="xs" wrap="nowrap">
        <Button data-autofocus variant="default" fullWidth onClick={() => context.closeModal(id)}>
          {cancelButtonText}
        </Button>

        <Button
          color="red"
          fullWidth
          loading={isAwaiting}
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </Button>
      </Group>
    </Stack>
  );
}

ConfirmationModal.key = 'confirmation';
