import { ComponentProps } from 'react';

export function Logo(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127 30" fill="none" {...props}>
      <path d="M6.98284 29.2985C6.709 29.381 6.27086 29.4635 5.66842 29.5461C5.09336 29.6286 4.49092 29.6699 3.8611 29.6699C3.25866 29.6699 2.71098 29.6286 2.21808 29.5461C1.75256 29.4635 1.35549 29.2985 1.02689 29.0509C0.698284 28.8033 0.438139 28.4732 0.246453 28.0605C0.082151 27.6204 0 27.0564 0 26.3686V13.989C0 13.3563 0.109535 12.8198 0.328604 12.3796C0.575057 11.912 0.917353 11.4993 1.35549 11.1417C1.79363 10.784 2.32761 10.4677 2.95744 10.1926C3.61464 9.88996 4.31293 9.62861 5.05229 9.40853C5.79165 9.18845 6.55839 9.02338 7.35252 8.91334C8.14664 8.77579 8.94077 8.70702 9.73489 8.70702C11.0493 8.70702 12.0899 8.96836 12.8566 9.49106C13.6234 9.98624 14.0067 10.8116 14.0067 11.967C14.0067 12.3521 13.952 12.7373 13.8424 13.1224C13.7329 13.4801 13.596 13.8102 13.4317 14.1128C12.8566 14.1128 12.2679 14.1403 11.6654 14.1953C11.063 14.2503 10.4743 14.3329 9.8992 14.4429C9.32414 14.553 8.77647 14.6768 8.25618 14.8143C7.76327 14.9243 7.33882 15.0619 6.98284 15.227V29.2985Z" fill="black" />

      <path d="M26.6817 30C25.0661 30 23.56 29.7799 22.1634 29.3398C20.7943 28.8721 19.5894 28.1843 18.5488 27.2765C17.5356 26.3686 16.7278 25.227 16.1253 23.8514C15.5503 22.4759 15.2628 20.8666 15.2628 19.0234C15.2628 17.2077 15.5503 15.6534 16.1253 14.3604C16.7278 13.0399 17.5082 11.967 18.4666 11.1417C19.4251 10.2889 20.5204 9.66988 21.7527 9.28473C22.985 8.87208 24.2446 8.66575 25.5316 8.66575C26.983 8.66575 28.2974 8.88583 29.4749 9.326C30.6798 9.76616 31.7067 10.3714 32.5555 11.1417C33.4318 11.912 34.1027 12.8336 34.5682 13.9065C35.0612 14.9794 35.3076 16.1486 35.3076 17.414C35.3076 18.3494 35.0475 19.0646 34.5272 19.5598C34.0069 20.055 33.2812 20.3714 32.3502 20.5089L22.2045 22.0358C22.5057 22.9436 23.1219 23.6314 24.0529 24.099C24.984 24.5392 26.0519 24.7593 27.2568 24.7593C28.3795 24.7593 29.4338 24.6217 30.4196 24.3466C31.4328 24.044 32.2543 23.7001 32.8841 23.315C33.3223 23.5901 33.692 23.9752 33.9932 24.4704C34.2944 24.9656 34.445 25.4883 34.445 26.0385C34.445 27.2765 33.87 28.1981 32.7198 28.8033C31.8436 29.271 30.8578 29.5873 29.7624 29.7524C28.6671 29.9175 27.6402 30 26.6817 30ZM25.5316 13.7827C24.8744 13.7827 24.2994 13.8927 23.8065 14.1128C23.3409 14.3329 22.9576 14.6217 22.6564 14.9794C22.3551 15.3095 22.1224 15.6946 21.9581 16.1348C21.8211 16.5475 21.739 16.9739 21.7116 17.414L28.7355 16.2586C28.6534 15.7084 28.3522 15.1582 27.8319 14.608C27.3116 14.0578 26.5448 13.7827 25.5316 13.7827Z" fill="black" />

      <path d="M51.8578 28.8033C51.4745 29.1059 50.9131 29.3535 50.1737 29.5461C49.4344 29.7387 48.5992 29.8349 47.6681 29.8349C46.6002 29.8349 45.6691 29.6974 44.875 29.4223C44.1082 29.1472 43.5743 28.707 43.273 28.1018C43.0266 27.6341 42.7254 26.9876 42.3694 26.1623C42.0408 25.3095 41.6848 24.3741 41.3014 23.3563C40.918 22.3109 40.521 21.1967 40.1102 20.0138C39.6995 18.8308 39.3024 17.6616 38.919 16.5062C38.5631 15.3508 38.2344 14.2366 37.9332 13.1637C37.632 12.0908 37.3856 11.1417 37.1939 10.3164C37.5772 9.93122 38.0838 9.6011 38.7137 9.326C39.3709 9.02338 40.0828 8.87208 40.8496 8.87208C41.808 8.87208 42.5885 9.0784 43.1909 9.49106C43.8207 9.8762 44.2862 10.619 44.5875 11.7194L46.1894 18.033C46.4906 19.271 46.7782 20.3989 47.052 21.4168C47.3258 22.4072 47.5312 23.1774 47.6681 23.7276H47.8735C48.3938 21.6919 48.9415 19.436 49.5165 16.9601C50.0916 14.4842 50.6119 12.0495 51.0774 9.65612C51.5429 9.40853 52.0632 9.21596 52.6383 9.0784C53.2407 8.94085 53.8158 8.87208 54.3634 8.87208C55.3219 8.87208 56.1297 9.0784 56.7869 9.49106C57.4441 9.90371 57.7727 10.619 57.7727 11.6369C57.7727 12.077 57.6632 12.7235 57.4441 13.5763C57.2524 14.4016 56.9923 15.3508 56.6637 16.4237C56.3624 17.4691 55.9928 18.5832 55.5546 19.7662C55.1439 20.9491 54.7194 22.1045 54.2813 23.2325C53.8431 24.3604 53.405 25.4195 52.9669 26.4099C52.5561 27.3728 52.1864 28.1706 51.8578 28.8033Z" fill="black" />

      <path d="M71.2471 30C69.6314 30 68.1253 29.7799 66.7288 29.3398C65.3596 28.8721 64.1547 28.1843 63.1141 27.2765C62.1009 26.3686 61.2931 25.227 60.6907 23.8514C60.1156 22.4759 59.8281 20.8666 59.8281 19.0234C59.8281 17.2077 60.1156 15.6534 60.6907 14.3604C61.2931 13.0399 62.0735 11.967 63.032 11.1417C63.9904 10.2889 65.0857 9.66988 66.318 9.28473C67.5503 8.87208 68.8099 8.66575 70.0969 8.66575C71.5483 8.66575 72.8627 8.88583 74.0402 9.326C75.2451 9.76616 76.272 10.3714 77.1209 11.1417C77.9971 11.912 78.668 12.8336 79.1336 13.9065C79.6265 14.9794 79.8729 16.1486 79.8729 17.414C79.8729 18.3494 79.6128 19.0646 79.0925 19.5598C78.5722 20.055 77.8465 20.3714 76.9155 20.5089L66.7698 22.0358C67.0711 22.9436 67.6872 23.6314 68.6182 24.099C69.5493 24.5392 70.6172 24.7593 71.8221 24.7593C72.9449 24.7593 73.9991 24.6217 74.9849 24.3466C75.9981 24.044 76.8196 23.7001 77.4495 23.315C77.8876 23.5901 78.2573 23.9752 78.5585 24.4704C78.8597 24.9656 79.0103 25.4883 79.0103 26.0385C79.0103 27.2765 78.4353 28.1981 77.2852 28.8033C76.4089 29.271 75.4231 29.5873 74.3277 29.7524C73.2324 29.9175 72.2055 30 71.2471 30ZM70.0969 13.7827C69.4397 13.7827 68.8647 13.8927 68.3718 14.1128C67.9063 14.3329 67.5229 14.6217 67.2217 14.9794C66.9204 15.3095 66.6877 15.6946 66.5234 16.1348C66.3865 16.5475 66.3043 16.9739 66.2769 17.414L73.3008 16.2586C73.2187 15.7084 72.9175 15.1582 72.3972 14.608C71.8769 14.0578 71.1101 13.7827 70.0969 13.7827Z" fill="black" />

      <path d="M90.3401 29.2985C90.0389 29.3535 89.5871 29.4223 88.9846 29.5048C88.4096 29.6149 87.8208 29.6699 87.2184 29.6699C86.6159 29.6699 86.0683 29.6286 85.5754 29.5461C85.1098 29.4635 84.7128 29.2985 84.3842 29.0509C84.0556 28.8033 83.7954 28.4732 83.6037 28.0605C83.4394 27.6204 83.3573 27.0564 83.3573 26.3686V2.43466C83.6585 2.37964 84.0966 2.31087 84.6717 2.22834C85.2741 2.11829 85.8766 2.06327 86.479 2.06327C87.0815 2.06327 87.6154 2.10454 88.081 2.18707C88.5739 2.2696 88.9846 2.43466 89.3132 2.68226C89.6418 2.92985 89.8883 3.27373 90.0526 3.71389C90.2443 4.12655 90.3401 4.67675 90.3401 5.36451V29.2985Z" fill="black" />

      <path d="M94.5821 3.71389C94.5821 2.6685 94.9244 1.78817 95.609 1.0729C96.321 0.357634 97.252 0 98.4021 0C99.5523 0 100.47 0.357634 101.154 1.0729C101.866 1.78817 102.222 2.6685 102.222 3.71389C102.222 4.75928 101.866 5.63962 101.154 6.35488C100.47 7.07015 99.5523 7.42779 98.4021 7.42779C97.252 7.42779 96.321 7.07015 95.609 6.35488C94.9244 5.63962 94.5821 4.75928 94.5821 3.71389ZM101.894 29.2985C101.592 29.3535 101.141 29.4223 100.538 29.5048C99.963 29.6149 99.3743 29.6699 98.7718 29.6699C98.1694 29.6699 97.6217 29.6286 97.1288 29.5461C96.6633 29.4635 96.2662 29.2985 95.9376 29.0509C95.609 28.8033 95.3489 28.4732 95.1572 28.0605C94.9929 27.6204 94.9107 27.0564 94.9107 26.3686V9.57359C95.2119 9.51857 95.6501 9.44979 96.2251 9.36726C96.8276 9.25722 97.43 9.2022 98.0325 9.2022C98.6349 9.2022 99.1689 9.24347 99.6344 9.326C100.127 9.40853 100.538 9.57359 100.867 9.82118C101.195 10.0688 101.442 10.4127 101.606 10.8528C101.798 11.2655 101.894 11.8157 101.894 12.5034V29.2985Z" fill="black" />

      <path d="M127 19.3122C127 21.0179 126.74 22.5447 126.22 23.8927C125.699 25.2132 124.96 26.3274 124.001 27.2352C123.07 28.1431 121.948 28.8308 120.633 29.2985C119.319 29.7662 117.854 30 116.238 30C114.623 30 113.158 29.7524 111.843 29.2572C110.529 28.762 109.392 28.0605 108.434 27.1527C107.503 26.2173 106.777 25.0894 106.257 23.7689C105.737 22.4484 105.476 20.9629 105.476 19.3122C105.476 17.6891 105.737 16.2173 106.257 14.8968C106.777 13.5763 107.503 12.4622 108.434 11.5543C109.392 10.619 110.529 9.90371 111.843 9.40853C113.158 8.91334 114.623 8.66575 116.238 8.66575C117.854 8.66575 119.319 8.9271 120.633 9.44979C121.948 9.94498 123.07 10.6602 124.001 11.5956C124.96 12.5034 125.699 13.6176 126.22 14.9381C126.74 16.2586 127 17.7166 127 19.3122ZM112.624 19.3122C112.624 20.9904 112.938 22.2834 113.568 23.1912C114.226 24.0715 115.129 24.5117 116.279 24.5117C117.429 24.5117 118.306 24.0578 118.908 23.1499C119.538 22.2421 119.853 20.9629 119.853 19.3122C119.853 17.6616 119.538 16.3961 118.908 15.5158C118.278 14.608 117.388 14.1541 116.238 14.1541C115.088 14.1541 114.198 14.608 113.568 15.5158C112.938 16.3961 112.624 17.6616 112.624 19.3122Z" fill="black" />
    </svg>
  );
}
