import { createRouter } from '@tanstack/react-router';

import { NothingFound } from '@/views/not-found';

import { routeTree } from '@/routeTree.gen';

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <NothingFound />,
});
