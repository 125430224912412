import { createFileRoute } from '@tanstack/react-router';

import { HomePageView } from '@/views/home-page';
import { HomePageHeader } from '@/views/home-page-header';

import { AdminLink } from '@/widgets/admin-link';
import { AppLayout } from '@/widgets/layouts/app';

import { workspacesQuery } from '@/entities/workspace';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/')({
  component: Component,
  async loader() {
    await queryClient.prefetchQuery(workspacesQuery());
  },
});

function Component() {
  return (
    <AppLayout sidebar={<AdminLink />}>
      <HomePageHeader />

      <HomePageView />
    </AppLayout>
  );
}
