import {
  DefaultError,
  QueryKey,
  SkipToken,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
  useSuspenseQuery,
} from '@tanstack/react-query';

import { Require } from '@/shared/types';

export type QueryResult<
  Q extends (...args: any[]) => { queryFn?: SkipToken | ((...args: any[]) => any) },
> = ReturnType<Q>['queryFn'] extends undefined
  ? unknown
  : Awaited<ReturnType<Exclude<ReturnType<Q>['queryFn'], undefined | SkipToken>>>;

export function defineQuery<
  TPayload extends unknown[],
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(fn: (...args: TPayload) => Require<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'>) {
  const queryOptionsFactory = (...args: TPayload) => fn(...args);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hook = (...args: TPayload) => useQuery(queryOptionsFactory(...args));

  return [hook, queryOptionsFactory] as const;
}

export function defineSuspenseQuery<
  TPayload extends unknown[],
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(fn: (...args: TPayload) => Require<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryFn'>) {
  const queryOptionsFactory = (...args: TPayload) => fn(...args);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hook = (...args: TPayload) => useSuspenseQuery(queryOptionsFactory(...args));

  return [hook, queryOptionsFactory] as const;
}

type InfiniteQueryOptions<
  TData = unknown,
  TErr = DefaultError,
  TKey extends QueryKey = QueryKey,
> = Require<Omit<UseInfiniteQueryOptions<TData, TErr, TData, TData, TKey, string | null>, 'initialPageParam'>, 'queryFn'>;

export function defineInfiniteQuery<
  TPayload extends unknown[],
  TData = unknown,
  TError = DefaultError,
  TQueryKey extends QueryKey = QueryKey,
>(fn: (...args: TPayload) => InfiniteQueryOptions<TData, TError, TQueryKey>) {
  const queryOptionsFactory = (...args: TPayload) => fn(...args);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hook = (...args: TPayload) => useInfiniteQuery({
    ...queryOptionsFactory(...args),
    initialPageParam: null as null | string,
  });

  return [hook, queryOptionsFactory] as const;
}
