import { createFileRoute } from '@tanstack/react-router';

import { latestUpdatesPagesQuery } from '@/entities/page';
import { workspaceQuery } from '@/entities/workspace';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug/')({
  async loader({ params: { workspaceSlug } }) {
    await Promise.all([
      queryClient.prefetchQuery(workspaceQuery(workspaceSlug)),
      queryClient.prefetchQuery(latestUpdatesPagesQuery({ workspaceSlug })),
    ]);
  },
});
