import './styles.css';

import { PropsWithChildren, useEffect } from 'react';

import { useRouter } from '@tanstack/react-router';
import NProgress from 'nprogress';

export function ProgressBar({ children }: PropsWithChildren) {
  const router = useRouter();
  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    router.subscribe('onBeforeNavigate', () => {
      NProgress.start();
    });

    router.subscribe('onResolved', () => {
      NProgress.done();
    });
  }, [router]);

  return <>{children}</>;
}
