import { createFileRoute } from '@tanstack/react-router';

import { pageSummaryQuery } from '@/entities/page';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug/pages/$pageId/summary')({
  async loader({ params }) {
    await queryClient.prefetchQuery(pageSummaryQuery(params.pageId));
  },
});
