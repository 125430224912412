import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { groupQueryKeyFactory } from '@/entities/group';
import { usersQueryKeyFactory } from '@/entities/user';

import { api, UserDataDto, Users } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';
import { SESSION_QUERY_KEY, useCurrentUser } from '@/shared/session';

import { EDIT_USER_MODAL_KEY } from '../const';

export function useEditUserModal() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (userId: string) => openContextModal({
    modal: EDIT_USER_MODAL_KEY,
    title: 'Edit User',
    size: 'md',
    fullScreen: isMobile,
    radius: isMobile ? 0 : 'md',
    innerProps: {
      userId,
    },
  });
}

export function useUpdateUserMutation() {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();

  return useMutation({
    mutationFn: (payload: Users.UpdateUser.RequestParams & UserDataDto) => api.users.updateUser(payload.id, payload),
    async onSuccess({ id }) {
      const isCurrentUser = currentUser?.id === id;

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.single(id) }),
        queryClient.invalidateQueries({ queryKey: groupQueryKeyFactory.list() }),
        isCurrentUser && queryClient.invalidateQueries({ queryKey: SESSION_QUERY_KEY }),
      ]);

      notifySuccess('User has been updated');
    },
  });
}
