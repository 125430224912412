import { api, Pages } from '@/shared/api';
import { defineQuery, defineSuspenseQuery } from '@/shared/lib';

export const pagesQueryKeyFactory = {
  all: () => ['pages'] as const,
  singleById: (id: string) => [...pagesQueryKeyFactory.all(), 'single', id] as const,
  summmary: (id: string) => [...pagesQueryKeyFactory.singleById(id), 'summary'] as const,
  lists: () => [...pagesQueryKeyFactory.all(), 'lists'] as const,
  children: () => [...pagesQueryKeyFactory.lists(), 'children'] as const,
  concreteChildren: (payload: Pages.GetChildrenPages.RequestQuery) => [...pagesQueryKeyFactory.children(), payload] as const,
  parents: (id: string) => [...pagesQueryKeyFactory.lists(), 'parents', id] as const,
  latestUpdated: (payload: Pages.GetLatestUpdatedPages.RequestQuery) => [
    ...pagesQueryKeyFactory.lists(),
    'latestUpdated',
    payload,
  ] as const,
};

interface PageByIdQueryOptions {
  id: string;
  enabled?: boolean;
}

export const [usePageByIdQuery, pageByIdQuery] = defineSuspenseQuery(({ enabled = true, id }: PageByIdQueryOptions) => ({
  queryKey: pagesQueryKeyFactory.singleById(id),
  queryFn: () => api.pages.getPage(id),
  enabled,
}));

type ChildrenPagesQueryOptions = Pages.GetChildrenPages.RequestQuery & {
  enabled?: boolean;
};

export const [useChildrenPagesQuery, childrenPagesQuery] = defineQuery(
  ({ enabled, ...payload }: ChildrenPagesQueryOptions) => ({
    queryKey: pagesQueryKeyFactory.concreteChildren(payload),
    queryFn: () => api.pages.getChildrenPages(payload),
    enabled,
  }),
);

interface ParentPagesQueryOptions {
  pageId: string;
  enabled?: boolean;
}

export const [
  useParentPagesQuery,
  parentPagesQuery,
] = defineQuery(({ pageId, enabled = true }: ParentPagesQueryOptions) => ({
  queryKey: pagesQueryKeyFactory.parents(pageId),
  queryFn: () => api.pages.getParentPages(pageId),
  enabled,
}));

export const [
  useLatestUpdatedPagesQuery,
  latestUpdatesPagesQuery,
] = defineQuery((payload: Pages.GetLatestUpdatedPages.RequestQuery) => ({
  queryKey: pagesQueryKeyFactory.latestUpdated(payload),
  queryFn: () => api.pages.getLatestUpdatedPages(payload),
}));

export const [
  usePageSummaryQuery,
  pageSummaryQuery,
] = defineQuery((pageId: string) => ({
  queryKey: pagesQueryKeyFactory.summmary(pageId),
  queryFn: () => api.pages.getPageSummary(pageId),
  refetchInterval: 10000,
}));
