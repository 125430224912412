import { useEffect } from 'react';

import { Button, Group, Stack, Textarea, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import slugify from 'slugify';

import { useAsyncForm } from '@/shared/hooks';

import { useCreateWorkspaceMutation } from '../hooks';

export function NewWorkspaceModal({ id, context }: ContextModalProps) {
  const form = useAsyncForm({
    initialValues: {
      title: '',
      description: '',
      slug: '',
    },
  });

  useEffect(() => {
    form.setFieldValue('slug', slugify(form.values.title, {
      lower: true,
      strict: true,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.values.title]);

  const close = () => context.closeModal(id);

  const { mutateAsync, isPending } = useCreateWorkspaceMutation({
    redirectToWorkspace: true,
  });

  const submit = async (values: typeof form.values) => {
    await mutateAsync(values);
    close();
  };

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack>
        <TextInput
          data-autofocus
          withAsterisk
          label="Title"
          {...form.getInputProps('title')}
        />

        <TextInput
          withAsterisk
          label="Slug"
          description="Part of the URL that identifies the workspace. Change this in case of conflicts with other workspaces."
          {...form.getInputProps('slug')}
        />

        <Textarea label="Description" {...form.getInputProps('description')} />
      </Stack>

      <Group mt="xl" justify="end">
        <Button type="submit" loading={isPending}>
          Create
        </Button>

        <Button variant="default" onClick={close}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
