import { Button, Checkbox, Group, Input, SimpleGrid, Stack, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { Permission, PERMISSIONS } from '@/shared/const';
import { useAsyncForm } from '@/shared/hooks';

import { useCreateGroupMutation } from '../hooks';

export function NewGroupModal({ id, context }: ContextModalProps) {
  const form = useAsyncForm({
    initialValues: {
      name: '',
      permissions: [] as Permission[],
    },
  });

  const close = () => context.closeModal(id);

  const { mutateAsync, isPending } = useCreateGroupMutation();
  const submit = async (values: typeof form.values) => {
    await mutateAsync(values);
    close();
  };

  return (
    <form onSubmit={form.onSubmit(submit)}>
      <Stack>
        <TextInput
          data-autofocus
          withAsterisk
          label="Name"
          {...form.getInputProps('name')}
        />

        <Checkbox.Group {...form.getInputProps('permissions')}>
          <SimpleGrid cols={2} verticalSpacing="lg" mb="sm">
            {PERMISSIONS.map(permissionsGroup => (
              <Input.Wrapper key={permissionsGroup.name} label={permissionsGroup.name}>
                <Stack mt="xs">
                  {permissionsGroup.permissions.map(permission => (
                    <Checkbox key={permission.key} label={permission.name} value={permission.key} />
                  ))}
                </Stack>
              </Input.Wrapper>
            ))}
          </SimpleGrid>
        </Checkbox.Group>
      </Stack>

      <Group mt="xl" justify="end">
        <Button type="submit" loading={isPending}>
          Create
        </Button>

        <Button variant="default" onClick={close}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
