import { PropsWithChildren } from 'react';

import { Box } from '@mantine/core';

import classes from './MobileToolbar.module.css';

export function MobileToolbar({ children }: PropsWithChildren) {
  return (
    <Box hiddenFrom="md" className={classes.root}>
      {children}
    </Box>
  );
}
