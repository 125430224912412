import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { groupQueryKeyFactory } from '@/entities/group';
import { usersQueryKeyFactory } from '@/entities/user';

import { api, Users } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

import { NEW_USER_MODAL_KEY } from '../const';

export function useNewUserModal() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return () => openContextModal({
    modal: NEW_USER_MODAL_KEY,
    title: 'New User',
    size: 'md',
    fullScreen: isMobile,
    radius: isMobile ? 0 : 'md',
    innerProps: {},
  });
}

export function useCreateUserMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Users.CreateUser.RequestBody) => api.users.createUser(payload),
    async onSuccess() {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: groupQueryKeyFactory.list() }),
      ]);

      notifySuccess('User has been created');
    },
  });
}

export function useSendInvitationEmailMutation() {
  return useMutation({
    mutationFn: (id: string) => api.users.sendInvitation(id),
    onSuccess() {
      notifySuccess('Invitation email has been sent');
    },
  });
}
