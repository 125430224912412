import { api } from '@/shared/api';
import { defineQuery } from '@/shared/lib';

export const workspaceQueryKeyFactory = {
  all: () => ['workspaces'] as const,
  list: () => [...workspaceQueryKeyFactory.all(), 'list'] as const,
  single: (slug: string) => [...workspaceQueryKeyFactory.all(), 'single', slug] as const,
};

export const [useWorkspacesQuery, workspacesQuery] = defineQuery(() => ({
  queryKey: workspaceQueryKeyFactory.list(),
  queryFn: () => api.workspaces.getWorkspaces(),
}));

export const [useWorkspaceQuery, workspaceQuery] = defineQuery((slug: string) => ({
  queryKey: workspaceQueryKeyFactory.single(slug),
  queryFn: () => api.workspaces.getWorkspace(slug),
}));
