import { useEffect } from 'react';

import { Button, Group, Input, Skeleton, Stack, Textarea, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useWorkspaceQuery } from '@/entities/workspace';

import { useAsyncForm } from '@/shared/hooks';

import { useUpdateWorkspaceMutation } from './hooks';

type Props = ContextModalProps<{
  slug: string;
}>;

export function EditWorkspaceModal({ id, context, innerProps: { slug } }: Props) {
  const { data: workspace, isLoading } = useWorkspaceQuery(slug);

  const form = useAsyncForm({
    initialValues: {
      title: '',
      description: '' as string | null,
    },
  });

  useEffect(() => {
    if (!workspace)
      return;

    const preparedValues = {
      title: workspace.title,
      description: workspace.description,
    };

    form.setValues(preparedValues);
    form.resetDirty(preparedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspace]);

  const { mutateAsync, isPending } = useUpdateWorkspaceMutation();
  const handleSave = async (values: typeof form.values) => {
    if (!workspace)
      return;

    await mutateAsync({ id: workspace.id, ...values });
    context.closeModal(id);
  };

  return (
    <form onSubmit={form.onSubmit(handleSave)}>
      <Stack>
        <Input.Wrapper withAsterisk label="Title">
          <Skeleton visible={isLoading}>
            <TextInput data-autofocus {...form.getInputProps('title')} />
          </Skeleton>
        </Input.Wrapper>

        <Input.Wrapper label="Description">
          <Skeleton visible={isLoading}>
            <Textarea {...form.getInputProps('description')} />
          </Skeleton>
        </Input.Wrapper>
      </Stack>

      <Group mt="xl" justify="end">
        <Button type="submit" disabled={isLoading || !form.isDirty()} loading={isPending}>
          Save
        </Button>

        <Button variant="default" onClick={() => context.closeModal(id, true)}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
