import { useMemo } from 'react';

import { Button, Group, Input, MultiSelect, Skeleton, Stack, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useGroupsQuery } from '@/entities/group';

import { useAsyncForm } from '@/shared/hooks';

import { useCreateUserMutation } from '../hooks';

type Props = ContextModalProps;

export function NewUserModal({ id, context }: Props) {
  const { data: groups = [], isLoading: isLoadingGroups } = useGroupsQuery();
  const groupOptions = useMemo(() => groups.map(group => ({
    value: group.id,
    label: group.name,
  })), [groups]);

  const form = useAsyncForm({
    initialValues: {
      email: '',
      name: '',
      groups: [] as string[],
    },
  });

  const { mutateAsync, isPending } = useCreateUserMutation();
  const handleSave = async (values: typeof form.values) => {
    await mutateAsync(values);
    context.closeModal(id);
  };

  return (
    <form onSubmit={form.onSubmit(handleSave)}>
      <Stack>
        <TextInput
          data-autofocus
          withAsterisk
          label="Name"
          {...form.getInputProps('name')}
        />

        <TextInput
          withAsterisk
          label="Email"
          {...form.getInputProps('email')}
        />

        <Input.Wrapper label="Groups" withAsterisk>
          <Skeleton visible={isLoadingGroups}>
            <MultiSelect
              checkIconPosition="right"
              data={groupOptions}
              {...form.getInputProps('groups')}
            />
          </Skeleton>
        </Input.Wrapper>
      </Stack>

      <Group mt="xl" justify="end">
        <Button type="submit" loading={isPending}>
          Create
        </Button>

        <Button variant="default" onClick={() => context.closeModal(id, true)}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
