import '@/app/sentry';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import '@mantine/spotlight/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';

import '@/app/global.css';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { ColorSchemeScript, MantineProvider } from '@mantine/core';

import { QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';

import { router } from './app/router';
import { queryClient } from './shared/api';
import { THEME } from './shared/theme';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ColorSchemeScript />

    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={THEME}>
        <RouterProvider router={router} />

      </MantineProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
