import { createFileRoute, notFound } from '@tanstack/react-router';
import { z } from 'zod';

import { groupsQuery } from '@/entities/group';

import { queryClient } from '@/shared/api';
import { Permission } from '@/shared/const';
import { hasPermission } from '@/shared/session';

export const Route = createFileRoute('/_protected/admin/groups')({
  validateSearch: z.object({
    group: z.string().optional(),
  }),
  async beforeLoad() {
    const canManageUsers = await hasPermission(Permission.ManageUsers);
    if (!canManageUsers)
      throw notFound();
  },
  async loader() {
    await queryClient.prefetchQuery(groupsQuery({
      countUsers: true,
    }));
  },
});
