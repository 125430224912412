import { ActionIcon, Button } from '@mantine/core';
import { IconFolderPlus } from '@tabler/icons-react';

import { PageHeader } from '@/widgets/layouts/app';

import { useNewWorkspaceModal } from '@/features/workspaces/create';

import { Permission } from '@/shared/const';
import { usePermission } from '@/shared/session';
import { Icon } from '@/shared/ui';

export function HomePageHeader() {
  const canCreateWorkspace = usePermission(Permission.WriteWorkspaces);
  const openModal = useNewWorkspaceModal();

  return (
    <PageHeader
      desktopActions={canCreateWorkspace && (
        <Button
          size="xs"
          leftSection={<Icon size={16} src={IconFolderPlus} />}
          onClick={openModal}
        >
          Create workspace
        </Button>
      )}
      mobileActions={canCreateWorkspace && (
        <ActionIcon size="lg" variant="transparent" onClick={openModal}>
          <Icon src={IconFolderPlus} size={24} />
        </ActionIcon>
      )}
    />
  );
}
