import { Button, Card, Stack, TextInput, Title } from '@mantine/core';

import { useMutation } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { api } from '@/shared/api';
import { useAsyncForm } from '@/shared/hooks';
import { notifySuccess } from '@/shared/lib';

import ErrorAlert from './-ErrorAlert';

export const Route = createFileRoute('/auth/signin')({
  component: Component,
  validateSearch: z.object({
    error: z.string().optional(),
  }),
});

function Component() {
  const form = useAsyncForm({
    initialValues: {
      email: '',
    },
  });

  const search = Route.useSearch();
  const navigate = Route.useNavigate();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => api.auth.emailAuth({
      email: form.values.email,
    }),
    onMutate() {
      navigate({
        replace: true,
        search: { ...search, error: undefined },
      });
    },
    onSuccess(res) {
      if (res.error) {
        return navigate({
          replace: true,
          search: { ...search, error: res.error || 'default' },
        });
      }

      form.reset();
      notifySuccess('Check your email for a sign in link');
    },
  });

  return (
    <Card withBorder>
      <Title order={3} mb="md">
        Sign In
      </Title>

      {search.error && (
        <ErrorAlert errorCode={search.error} />
      )}

      <form
        onSubmit={form.onSubmit(async () => {
          await mutateAsync();
        })}
      >
        <Stack>
          <TextInput
            withAsterisk
            label="Email"
            type="email"
            {...form.getInputProps('email')}
          />

          <Button type="submit" fullWidth loading={isPending}>
            Sign in
          </Button>

          <Button
            variant="default"
            fullWidth
            onClick={() => {
              window.location.href = '/api/auth/google';
            }}
          >
            Sign in with Google
          </Button>
        </Stack>
      </form>
    </Card>
  );
}
