import { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';

import { AppShell, Box, Burger, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { Link, useLocation } from '@tanstack/react-router';

import { AppLayoutProvider } from './AppLayout.context';
import { Logo } from './Logo';
import { ProfileMenu } from './ProfileMenu';

import classes from './AppLayout.module.css';

type Props = PropsWithChildren<{
  sidebar?: ReactNode;
}>;

export function AppLayout({ children, sidebar }: Props) {
  const [opened, { toggle, close }] = useDisclosure();
  const location = useLocation();

  useEffect(close, [close, location.pathname]);

  const ctx = useMemo(() => ({
    isSidebarOpened: opened,
    toggleSidebar: toggle,
  }), [opened, toggle]);

  return (
    <AppShell
      layout="alt"
      padding={{
        base: 'md',
        md: 'xl',
      }}
      header={{
        height: { base: 60, md: 44 },
      }}
      navbar={{
        width: { base: 240, md: 300 },
        breakpoint: 'md',
        collapsed: { mobile: !opened },
      }}
    >
      <AppLayoutProvider value={ctx}>
        <AppShell.Navbar p={16}>
          <AppShell.Section className={classes.logoWrapper}>
            <Burger
              hiddenFrom="md"
              className="no-print"
              opened={opened}
              onClick={toggle}
              size="sm"
              style={{ flex: 1 }}
            />

            <Box
              display="block"
              renderRoot={props => <Link {...props} to="/" />}
            >
              <Logo className={classes.logo} />
            </Box>

            <div style={{ flex: 1 }} />
          </AppShell.Section>

          <AppShell.Section
            className={classes.navbarSection}
            component={ScrollArea}
            scrollbarSize={8}
            grow
          >
            {sidebar}
          </AppShell.Section>

          <AppShell.Section className={classes.navbarSection}>
            <ProfileMenu />
          </AppShell.Section>
        </AppShell.Navbar>

        <AppShell.Main>
          {children}
        </AppShell.Main>
      </AppLayoutProvider>
    </AppShell>
  );
}
