import { createFileRoute, redirect } from '@tanstack/react-router';

import { pageByIdQuery } from '@/entities/page';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug/pages/$pageId/')({
  async beforeLoad({ params }) {
    const page = await queryClient.ensureQueryData(pageByIdQuery({ id: params.pageId }));
    if (page.isDraft) {
      throw redirect({
        ignoreBlocker: true,
        to: '/workspaces/$workspaceSlug/pages/$pageId/edit',
        params,
      });
    }
  },
});
