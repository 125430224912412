import { createFileRoute, notFound, Outlet } from '@tanstack/react-router';

import { pageByIdQuery, parentPagesQuery } from '@/entities/page';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug/pages/$pageId')({
  async loader({ params }) {
    const page = await queryClient.ensureQueryData(pageByIdQuery({ id: params.pageId }));
    if (!page) {
      throw notFound();
    }

    await queryClient.ensureQueryData(parentPagesQuery({ pageId: params.pageId }));
  },
  component: Component,
});

function Component() {
  const params = Route.useParams();
  return <Outlet key={params.pageId} />;
}
