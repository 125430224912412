import { Button, Container, Group, Stack, Text, Title } from '@mantine/core';

import { Link } from '@tanstack/react-router';

import { Illustration } from './Illustration';

import classes from './NothingFound.module.css';

interface Props {
  buttonText?: string;
  buttonLink?: string;
}

export function NothingFound({ buttonText = 'Go home', buttonLink = '/' }: Props) {
  return (
    <Container className={classes.root}>
      <div className={classes.inner}>
        <Illustration className={classes.image} />

        <Stack align="center" className={classes.content}>
          <Title className={classes.title}>Nothing to see here</Title>

          <Text c="dimmed" size="lg" ta="center" className={classes.description}>
            Page you are trying to open does not exist. You may have mistyped the address, or the
            page has been moved to another URL. Contact administrator if you think this is an error.
          </Text>

          <Group justify="center">
            <Button component={Link} to={buttonLink} size="md">
              {buttonText}
            </Button>
          </Group>
        </Stack>
      </div>
    </Container>
  );
}
