import { NavLink } from '@mantine/core';
import { IconSettings } from '@tabler/icons-react';

import { Link } from '@tanstack/react-router';

import { useAdminAccess } from '@/shared/session';
import { Icon } from '@/shared/ui';

export function AdminLink() {
  const canAccessAdminSection = useAdminAccess();

  return canAccessAdminSection && (
    <NavLink
      variant="sidebar"
      renderRoot={p => <Link {...p} to="/admin" />}
      title="Administration"
      label="Administration"
      leftSection={<Icon src={IconSettings} size={20} />}
    />
  );
}
