import { object, string } from 'zod';

export const pageSchema = object({
  id: string(),
  title: string()
    .min(3, 'Title must be at least 3 characters long')
    .max(60, 'Title must be at most 60 characters long'),
  content: string(),
  description: string()
    .max(255, 'Description must be at most 255 characters long')
    .nullable(),
  tags: string().array(),
  slug: string()
    .min(3, 'Slug must be at least 1 character long')
    .max(100, 'Slug must be less than 100 characters long')
    .regex(/^[\w-]*$/, 'Slug must contain only alphanumeric characters and dashes'),
  parentId: string().nullable(),
});

export const draftPageSchema = object({
  id: string(),
  title: string()
    .max(60, 'Title must be at most 60 characters long'),
  content: string(),
  description: string()
    .max(255, 'Description must be at most 255 characters long')
    .nullable(),
  tags: string().array(),
  parentId: string().nullable(),
});
