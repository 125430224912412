import { useDebouncedValue } from '@mantine/hooks';

import { infiniteQueryOptions, keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';

import { api, Tags } from '@/shared/api';
import { defineQuery } from '@/shared/lib';

export const tagQueryKeyFactory = {
  all: () => ['tags'] as const,
  list: () => [...tagQueryKeyFactory.all(), 'list'] as const,
  single: (id: string) => [...tagQueryKeyFactory.all(), 'single', id] as const,
  paginatedList: (p: Omit<Tags.GetTags.RequestQuery, 'pagination'> = {}) => [...tagQueryKeyFactory.list(), 'paginated', p] as const,
};

const TAGS_PAGE_SIZE = 10;

export function tagsQuery(payload: Omit<Tags.GetTags.RequestQuery, 'pagination'> = {}) {
  return infiniteQueryOptions({
    queryKey: tagQueryKeyFactory.paginatedList(payload),
    queryFn: ctx => api.tags.getTags({
      ...payload,
      pagination: {
        size: TAGS_PAGE_SIZE,
        cursor: ctx.pageParam,
      },
    }),
    placeholderData: keepPreviousData,
    select: data => data?.pages.flatMap(i => i.data) || [],
    initialPageParam: null as null | string,
    getNextPageParam(lastPage, pages) {
      const loadedPagesCount = pages.flatMap(page => page.data).length;

      return loadedPagesCount >= lastPage.total
        ? undefined
        : lastPage.cursor;
    },
  });
}

export function useTagsQuery(payload: Omit<Tags.GetTags.RequestQuery, 'pagination'> = {}) {
  const [debouncedSearch] = useDebouncedValue(payload.filter?.search, 350);
  const preparedPayload = {
    ...payload,
    filter: payload.filter && {
      ...payload.filter,
      search: debouncedSearch,
    },
  };

  return useInfiniteQuery(tagsQuery(preparedPayload));
}

export const [useTagQuery, tagQuery] = defineQuery((id: string) => ({
  queryKey: tagQueryKeyFactory.single(id),
  queryFn: () => api.tags.getTag(id),
}));
