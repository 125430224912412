import { createFileRoute, notFound, Outlet } from '@tanstack/react-router';

import { AdminSidebarView } from '@/views/admin-sidebar';

import { AppLayout } from '@/widgets/layouts/app';

import { hasAdminAccess } from '@/shared/session';

export const Route = createFileRoute('/_protected/admin')({
  component: Component,
  async beforeLoad() {
    const canAccessAdminSection = await hasAdminAccess();
    if (!canAccessAdminSection) {
      throw notFound();
    }
  },
});

function Component() {
  return (
    <AppLayout sidebar={<AdminSidebarView />}>
      <Outlet />
    </AppLayout>
  );
}
