'use client';

import { createTheme } from '@mantine/core';

import navLinkClasses from './NavLink.module.css';

export const THEME = createTheme({
  components: {
    Text: {
      classNames: {
        root: 'text-wrap',
      },
    },
    Title: {
      classNames: {
        root: 'text-wrap',
      },
    },
    NavLink: {
      classNames: navLinkClasses,
    },
    Tooltip: {
      defaultProps: {
        withArrow: true,
      },
    },
  },
});
