'use client';

import { createContext, PropsWithChildren, useContext } from 'react';

export interface AppLayoutContextProps {
  isSidebarOpened: boolean;
  toggleSidebar: () => void;
}

const AppLayoutContext = createContext<AppLayoutContextProps>({
  isSidebarOpened: false,
  toggleSidebar: () => {},
});

export function AppLayoutProvider({ children, value }: PropsWithChildren<{ value: AppLayoutContextProps }>) {
  return (
    <AppLayoutContext.Provider value={value}>
      {children}
    </AppLayoutContext.Provider>
  );
}

export const useAppLayout = () => useContext(AppLayoutContext);
