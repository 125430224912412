import { Avatar, Group, Menu, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { IconChevronDown, IconLogout } from '@tabler/icons-react';

import { useCurrentUser } from '@/shared/session';
import { Icon } from '@/shared/ui';

import classes from './ProfileMenu.module.css';

export function ProfileMenu() {
  const theme = useMantineTheme();
  const user = useCurrentUser();

  if (!user) {
    return null;
  }

  const avatarInitials = (user.name || '')
    .split(' ')
    .slice(0, 2)
    .map(name => name[0])
    .join('');

  return (
    <Menu
      width="target"
      position="bottom"
      transitionProps={{ transition: 'pop' }}
      withinPortal
    >
      <Menu.Target>
        <UnstyledButton className={classes.user}>
          <Group>
            <Avatar color={theme.primaryColor} radius="xl">
              {avatarInitials}
            </Avatar>

            <div style={{ flex: 1 }}>
              <Text size="sm" fw={500}>
                {user.name}
              </Text>

              <Text c="dimmed" size="xs">
                {user.email}
              </Text>
            </div>

            <Icon src={IconChevronDown} />
          </Group>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <form method="POST" action="/api/auth/logout">
          <Menu.Item
            component="button"
            type="submit"
            color="red"
            leftSection={<Icon src={IconLogout} />}
          >
            Logout
          </Menu.Item>
        </form>
      </Menu.Dropdown>
    </Menu>
  );
}
