import { keepPreviousData } from '@tanstack/react-query';

import { api, Users } from '@/shared/api';
import { defineQuery } from '@/shared/lib';

export const usersQueryKeyFactory = {
  all: () => ['users'] as const,
  single: (id: string) => [...usersQueryKeyFactory.all(), 'single', id] as const,
  list: () => [...usersQueryKeyFactory.all(), 'list'] as const,
  filteredList: (p: Users.GetUsers.RequestQuery) => [...usersQueryKeyFactory.list(), 'filtered', p] as const,
  current: () => [...usersQueryKeyFactory.all(), 'current'] as const,
};

export type UsersQueryOptions = Users.GetUsers.RequestQuery & {
  keepPreviousResult?: boolean;
};

export const [
  useUsersQuery,
  usersQuery,
] = defineQuery(({ keepPreviousResult, ...payload }: UsersQueryOptions = {}) => ({
  queryKey: usersQueryKeyFactory.filteredList(payload),
  queryFn: () => api.users.getUsers(payload),
  placeholderData: keepPreviousResult ? keepPreviousData : undefined,
}));

export const [useUserQuery] = defineQuery((id: string) => ({
  queryKey: usersQueryKeyFactory.single(id),
  queryFn: () => api.users.getUser(id),
}));
