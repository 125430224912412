import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { modals } from '@mantine/modals';

import { useMutation } from '@tanstack/react-query';

import { api, FileUploadDto, PageAttachmentDto, PageAttachments } from '@/shared/api';

import { UPLOAD_PAGE_ATTACHMENT_MODAK_KEY } from './const';

export function useUploadPageAttachment(pageId: string) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return () => new Promise<PageAttachmentDto>((resolve, reject) => {
    modals.openContextModal({
      modal: UPLOAD_PAGE_ATTACHMENT_MODAK_KEY,
      title: 'Upload File',
      size: 'lg',
      fullScreen: isMobile,
      radius: isMobile ? 0 : 'md',
      innerProps: {
        pageId,
        onSubmit: resolve,
        onCancel: reject,
      },
    });
  });
}

export function useUploadPageAttachmentMutation() {
  return useMutation({
    mutationFn: async (data: FileUploadDto & PageAttachments.Upload.RequestParams) => {
      return api.pageAttachments.upload(data.pageId, data);
    },
  });
}
