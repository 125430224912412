'use client';

import { Box, Button, Card, SimpleGrid, Text, Title } from '@mantine/core';
import { IconFolderPlus } from '@tabler/icons-react';

import { Link } from '@tanstack/react-router';

import { useNewWorkspaceModal } from '@/features/workspaces/create';

import { useWorkspacesQuery } from '@/entities/workspace';

import { Permission } from '@/shared/const';
import { formatRelativeTime } from '@/shared/lib';
import { usePermission } from '@/shared/session';
import { Icon } from '@/shared/ui';

import classes from './HomePageView.module.css';

export function HomePageView() {
  const { data: workspaces = [] } = useWorkspacesQuery();
  const openNewWorkspaceModal = useNewWorkspaceModal();
  const canWriteWorkspaces = usePermission(Permission.WriteWorkspaces);

  return (
    <Box mx="auto" w="100%" maw={800}>
      <Title order={1} mb="xl" ta="center" className={classes.title}>
        {'Welcome to your country discovery '}

        <Text inherit span variant="gradient" gradient={{ from: 'blue', to: 'grape' }}>
          app
        </Text>
      </Title>

      {workspaces.length === 0 && (
        <Box ta="center">
          <Text mb="md" fz="lg" c="dimmed" fs="italic">
            There are no any workspaces yet
          </Text>

          {canWriteWorkspaces && (
            <Button leftSection={<Icon src={IconFolderPlus} size={20} />} onClick={openNewWorkspaceModal}>
              New workspace
            </Button>
          )}
        </Box>
      )}

      <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }}>
        {workspaces.map(workspace => (
          <Card
            key={workspace.id}
            h="100%"
            mih={160}
            withBorder
            renderRoot={props => (
              <Link
                {...props}
                to="/workspaces/$workspaceSlug"
                params={{ workspaceSlug: workspace.slug }}
              />
            )}
          >
            <Text fw={500} size="lg">
              {workspace.title}
            </Text>

            {workspace.description && (
              <Text mt="xs" mb="sm" c="dimmed" size="sm">
                {workspace.description}
              </Text>
            )}

            <Text mt="auto" c="dimmed" size="sm">
              {`Updated ${formatRelativeTime(workspace.updatedAt)}`}
            </Text>
          </Card>
        ))}
      </SimpleGrid>
    </Box>
  );
}
