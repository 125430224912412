import { NavLink, Stack } from '@mantine/core';
import { IconTags, IconUsers, IconUsersGroup } from '@tabler/icons-react';

import { Link, useLocation } from '@tanstack/react-router';

import { Permission } from '@/shared/const';
import { usePermission } from '@/shared/session';
import { Icon } from '@/shared/ui';

export function AdminSidebarView() {
  const canManageUsers = usePermission(Permission.ManageUsers);
  const canManageTags = usePermission(Permission.ManageTags);
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <Stack gap={0}>
      {canManageUsers && (
        <>
          <NavLink
            variant="sidebar"
            label="Users"
            renderRoot={p => <Link {...p} to="/admin/users" />}
            active={pathname === '/admin/users'}
            leftSection={<Icon src={IconUsers} size={20} />}
          />

          <NavLink
            variant="sidebar"
            label="Groups"
            renderRoot={p => <Link {...p} to="/admin/groups" />}
            active={pathname.startsWith('/admin/groups')}
            leftSection={<Icon src={IconUsersGroup} size={20} />}
          />
        </>
      )}

      {canManageTags && (
        <NavLink
          variant="sidebar"
          label="Tags"
          renderRoot={p => <Link {...p} to="/admin/tags" />}
          active={pathname === '/admin/tags'}
          leftSection={<Icon src={IconTags} size={20} />}
        />
      )}
    </Stack>
  );
}
