import { createFileRoute, redirect } from '@tanstack/react-router';

import { Permission } from '@/shared/const';
import { hasPermission } from '@/shared/session';

export const Route = createFileRoute('/_protected/admin/')({
  async beforeLoad() {
    const canManageUsers = await hasPermission(Permission.ManageUsers);
    const canManageTags = await hasPermission(Permission.ManageTags);

    if (canManageUsers) {
      throw redirect({ to: '/admin/users', replace: true });
    }

    if (canManageTags) {
      throw redirect({ to: '/admin/tags', replace: true });
    }
  },
});
