import { PropsWithChildren, ReactNode } from 'react';

import { ActionIcon, AppShellHeader, Box, Burger, Container, Flex, Group, Menu } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';

import { Link } from '@tanstack/react-router';

import { Icon } from '@/shared/ui';

import { useAppLayout } from '../AppLayout.context';
import { Logo } from '../Logo';
import { MobileToolbar } from './MobileToolbar';

import classes from './PageHeader.module.css';

export interface PageHeaderProps extends PropsWithChildren {
  desktopLeftSection?: ReactNode;
  desktopActions?: ReactNode;
  mobileActions?: ReactNode;
  mobileMenu?: ReactNode;
  mobileToolbar?: ReactNode;
}

export function PageHeader({
  desktopLeftSection,
  desktopActions,
  mobileActions,
  mobileMenu,
  mobileToolbar,
}: PageHeaderProps) {
  const ctx = useAppLayout();

  return (
    <>
      <AppShellHeader withBorder={false}>
        <Container h="100%" fluid px={16}>
          <Group h="100%" gap="md" align="center" wrap="nowrap">
            <Burger
              hiddenFrom="md"
              className="no-print"
              opened={ctx.isSidebarOpened}
              onClick={ctx.toggleSidebar}
              size="sm"
              style={{ flex: 1 }}
            />

            <Box hiddenFrom="md" component={Link} display="block" href="/">
              <Box component={Logo} h={24} pb={4} display="block" />
            </Box>

            <Box className={classes.headerContent}>
              <Group wrap="nowrap" justify="space-between" align="center">
                <Box miw={0}>
                  <Box visibleFrom="md" display="contents">
                    {desktopLeftSection}
                  </Box>
                </Box>

                <Group gap={2} wrap="nowrap">
                  {desktopActions && (
                    <Box visibleFrom="md" display="contents">
                      {desktopActions}
                    </Box>
                  )}

                  {mobileActions && (
                    <Box hiddenFrom="md" display="contents">
                      {mobileActions}
                    </Box>
                  )}

                  {mobileMenu && (
                    <Flex hiddenFrom="md" justify="end" style={{ flex: '1 0 auto' }}>
                      <Menu
                        position="top-end"
                        transitionProps={{
                          duration: 200,
                          transition: 'pop-top-right',
                        }}
                      >
                        <Menu.Target>
                          <ActionIcon size="lg" variant="transparent" color="default">
                            <Icon src={IconDots} size={24} />
                          </ActionIcon>
                        </Menu.Target>

                        <Menu.Dropdown>
                          {mobileMenu}
                        </Menu.Dropdown>
                      </Menu>
                    </Flex>
                  )}
                </Group>
              </Group>
            </Box>
          </Group>
        </Container>
      </AppShellHeader>

      {mobileToolbar && (
        <MobileToolbar>
          {mobileToolbar}
        </MobileToolbar>
      )}
    </>
  );
}
