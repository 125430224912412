import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { groupQueryKeyFactory } from '@/entities/group';
import { usersQueryKeyFactory } from '@/entities/user';

import { api, Groups } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

import { EDIT_GROUP_MODAL_KEY } from './const';

export function useEditGroupModal() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (groupId: string) => openContextModal({
    modal: EDIT_GROUP_MODAL_KEY,
    title: 'Edit Group',
    size: 'md',
    fullScreen: isMobile,
    radius: isMobile ? 0 : 'md',
    innerProps: {
      groupId,
    },
  });
}

export function useUpdateGroupMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: Groups.UpdateGroup.RequestBody & Groups.UpdateGroup.RequestParams) =>
      api.groups.updateGroup(payload.id, payload),
    async onSuccess({ id }) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: usersQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: groupQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: groupQueryKeyFactory.single(id) }),
      ]);

      notifySuccess('Group has been updated');
    },
  });
}
