'use client';

import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { api, queryClient } from '@/shared/api';
import { ADMIN_PERMESSIONS, Permission } from '@/shared/const';

import { SESSION_QUERY_KEY } from './const';

export const sessionQuery = queryOptions({
  queryKey: SESSION_QUERY_KEY,
  queryFn: async () => {
    try {
      return await api.auth.getMe();
    } catch (e) {
      if (e instanceof Response && e.status === 401) {
        return null;
      }

      throw e;
    }
  },
});

export function getCurrentUser() {
  return queryClient.fetchQuery(sessionQuery);
}

export async function hasPermission(permission: Permission) {
  const user = await getCurrentUser();
  const permissions = user?.permissions || [];

  return permissions.includes(permission)
    || permissions.includes(Permission.Everything);
}

export async function hasAnyPermission(permissions: Permission[]) {
  const user = await getCurrentUser();
  const userPermissions = user?.permissions || [];

  return permissions.some(permission => userPermissions.includes(permission))
    || userPermissions.includes(Permission.Everything);
}

export async function hasAdminAccess() {
  return hasAnyPermission(ADMIN_PERMESSIONS);
}

export function useCurrentUser() {
  const { data } = useSuspenseQuery(sessionQuery);

  return data;
}

function useCurrentUserPermissions() {
  const user = useCurrentUser();

  return user?.permissions || [];
}

export function usePermission(permission: Permission) {
  const userPermissions = useCurrentUserPermissions();

  return userPermissions.includes(permission)
    || userPermissions.includes(Permission.Everything);
}

export function useAnyPermission(permissions: Permission[]) {
  const userPermissions = useCurrentUserPermissions();

  return permissions.some(permission => userPermissions.includes(permission))
    || userPermissions.includes(Permission.Everything);
}

export function useAdminAccess() {
  return useAnyPermission(ADMIN_PERMESSIONS);
}
