import { useCallback, useState } from 'react';

export function useAwaitable<T extends (...args: any[]) => Promise<any>>(fn: T, deps?: any[]) {
  type ResolvedReturnType = ReturnType<T> extends Promise<infer U> ? U : never;

  const [isLoading, setIsLoading] = useState(false);
  const decoratedFn = useCallback(async (...args: Parameters<T>): Promise<ResolvedReturnType> => {
    setIsLoading(true);
    try {
      return await fn(...args);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps || [fn]);

  return [decoratedFn, isLoading] as const;
}
