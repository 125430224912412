import { useEffect } from 'react';

import { Button, Group, Input, Skeleton, Stack, TextInput } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';

import { useTagQuery } from '@/entities/tag';

import { useAsyncForm } from '@/shared/hooks';

import { useUpdateTagMutation } from './hooks';

type Props = ContextModalProps<{
  tagId: string;
}>;

export function EditTagModal({ id, context, innerProps: { tagId } }: Props) {
  const { data: tag, isLoading } = useTagQuery(tagId);

  const form = useAsyncForm({
    initialValues: {
      id: tagId,
      name: '',
    },
  });

  useEffect(() => {
    if (!tag || form.isDirty())
      return;

    const preparedValues = {
      id: tagId,
      name: tag.name,
    };

    form.setValues(preparedValues);
    form.resetDirty(preparedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  const { mutateAsync, isPending } = useUpdateTagMutation();
  const handleSave = async (values: typeof form.values) => {
    if (!tag)
      return;

    await mutateAsync(values);
    context.closeModal(id);
  };

  return (
    <form onSubmit={form.onSubmit(handleSave)}>
      <Stack>
        <Input.Wrapper withAsterisk label="Name">
          <Skeleton visible={isLoading}>
            <TextInput data-autofocus {...form.getInputProps('name')} />
          </Skeleton>
        </Input.Wrapper>
      </Stack>

      <Group mt="xl" justify="end">
        <Button
          type="submit"
          disabled={isLoading || !form.isDirty()}
          loading={isPending}
        >
          Save
        </Button>

        <Button variant="default" onClick={() => context.closeModal(id, true)}>
          Cancel
        </Button>
      </Group>
    </form>
  );
}
