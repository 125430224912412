import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { tagQueryKeyFactory } from '@/entities/tag';

import { api, TagDataDto } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

import { NEW_TAG_MODAL_KEY } from './const';

export function useNewTagModal() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return () => openContextModal({
    modal: NEW_TAG_MODAL_KEY,
    title: 'New Tag',
    size: 'md',
    fullScreen: isMobile,
    radius: isMobile ? 0 : 'md',
    innerProps: {},
  });
}

export function useCreateTagMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (p: TagDataDto) => api.tags.createTag(p),
    async onSuccess({ id, name }) {
      notifySuccess(`Tag "${name}" has been created`);

      queryClient.invalidateQueries({ queryKey: tagQueryKeyFactory.list() });
      queryClient.invalidateQueries({ queryKey: tagQueryKeyFactory.single(id) });
    },
  });
}
