import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { workspaceQueryKeyFactory } from '@/entities/workspace';

import { api, UpdateWorkspaceDto, Workspaces } from '@/shared/api';
import { Permission } from '@/shared/const';
import { notifySuccess } from '@/shared/lib';
import { usePermission } from '@/shared/session';

import { EDIT_WORKSPACE_MODAL_KEY } from './const';

export function useEditWorkspace() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const canEditWorkspace = usePermission(Permission.WriteWorkspaces);

  const openEditModal = (slug: string) => openContextModal({
    modal: EDIT_WORKSPACE_MODAL_KEY,
    title: 'Edit Workspace',
    size: 'md',
    fullScreen: isMobile,
    radius: isMobile ? 0 : 'md',
    innerProps: {
      slug,
    },
  });

  return {
    editWorkspace: openEditModal,
    canEditWorkspace,
  };
}

export function useUpdateWorkspaceMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (payload: UpdateWorkspaceDto & Workspaces.UpdateWorkspace.RequestParams) =>
      api.workspaces.updateWorkspace(payload.id, payload),
    async onSuccess({ slug }) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: workspaceQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: workspaceQueryKeyFactory.single(slug) }),
      ]);

      notifySuccess('Workspace has been updated');
    },
  });
}
