import * as Sentry from '@sentry/react';

import { router } from './router';

Sentry.init({
  enabled: import.meta.env.PROD,
  release: import.meta.env.VITE_VERSION,
  environment: import.meta.env.VITE_ENV,
  dsn: 'https://004459816106a6bc3db12db897966d94@o4507849135095808.ingest.de.sentry.io/4508221888725072',
  integrations: [
    Sentry.tanstackRouterBrowserTracingIntegration(router),
  ],
  sampleRate: 1.0,
});
