import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { openContextModal } from '@mantine/modals';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

import { workspaceQueryKeyFactory } from '@/entities/workspace';

import { api, CreateWorkspaceDto } from '@/shared/api';
import { notifySuccess } from '@/shared/lib';

import { NEW_WORKSPACE_MODAL_KEY } from './const';

export function useNewWorkspaceModal() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return () => openContextModal({
    modal: NEW_WORKSPACE_MODAL_KEY,
    title: 'New Workspace',
    size: 'md',
    fullScreen: isMobile,
    radius: isMobile ? 0 : 'md',
    innerProps: {},
  });
}

export function useCreateWorkspaceMutation({ redirectToWorkspace = false }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (payload: CreateWorkspaceDto) => api.workspaces.createWorkspace(payload),
    async onSuccess(workspace) {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: workspaceQueryKeyFactory.list() }),
        queryClient.invalidateQueries({ queryKey: workspaceQueryKeyFactory.single(workspace.slug) }),
      ]);

      notifySuccess('Workspace has been created');
      if (redirectToWorkspace) {
        navigate({
          to: `/workspaces/$workspaceSlug`,
          params: { workspaceSlug: workspace.slug },
        });
      }
    },
  });
}
