import { createFileRoute } from '@tanstack/react-router';

import { childrenPagesQuery } from '@/entities/page';
import { workspaceQuery } from '@/entities/workspace';

import { queryClient } from '@/shared/api';

export const Route = createFileRoute('/_protected/workspaces/$workspaceSlug')({
  async loader({ params }) {
    await Promise.all([
      queryClient.prefetchQuery(workspaceQuery(params.workspaceSlug)),
      queryClient.prefetchQuery(childrenPagesQuery({
        workspaceSlug: params.workspaceSlug,
        parentId: null,
      })),
    ]);
  },
});
