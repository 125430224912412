import { ReactNode } from 'react';

import { Alert, Box } from '@mantine/core';

interface ErrorView {
  heading: string;
  message: ReactNode;
}

const errors: Record<string, ErrorView> = {
  default: {
    heading: 'Error',
    message: 'Unexpected error occured.',
  },
  configuration: {
    heading: 'Server error',
    message: 'There is a problem with the server configuration. Please contact administrator.',
  },
  accessdenied: {
    heading: 'Access denied',
    message: 'You do not have permission to sign in.',
  },
  verification: {
    heading: 'Unable to sign in',
    message: 'The sign in link is not valid. It may have been already used or it may have expired.',
  },
};

export default function SignInPage({ errorCode }: { errorCode: string }) {
  if (!errorCode) {
    return null;
  }

  const { heading, message } = errors[errorCode.toLowerCase()] ?? errors.default;

  return (
    <Box mb="md">
      <Alert title={heading} color="red">
        {message}
      </Alert>
    </Box>
  );
}
