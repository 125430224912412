import { PropsWithChildren } from 'react';

import { ModalsProvider as BaseModalsProvider } from '@mantine/modals';

import { NEW_GROUP_MODAL_KEY, NewGroupModal } from '@/features/groups/create';
import { EDIT_GROUP_MODAL_KEY, EditGroupModal } from '@/features/groups/edit';
import { UPLOAD_PAGE_ATTACHMENT_MODAK_KEY as UPLOAD_PAGE_ATTACHMENT_MODAL_KEY, UploadAttachmentModal } from '@/features/pages/upload-attachment';
import { NEW_TAG_MODAL_KEY, NewTagModal } from '@/features/tags/create';
import { EDIT_TAG_MODAL_KEY, EditTagModal } from '@/features/tags/edit';
import { NEW_USER_MODAL_KEY, NewUserModal } from '@/features/users/create';
import { EDIT_USER_MODAL_KEY, EditUserModal } from '@/features/users/edit';
import { NEW_WORKSPACE_MODAL_KEY, NewWorkspaceModal } from '@/features/workspaces/create';
import { EDIT_WORKSPACE_MODAL_KEY, EditWorkspaceModal } from '@/features/workspaces/edit';

import { CONFIRMATION_MODAL_KEY, ConfirmationModal } from '@/shared/ui';

const MODALS = {
  [CONFIRMATION_MODAL_KEY]: ConfirmationModal,
  [NEW_WORKSPACE_MODAL_KEY]: NewWorkspaceModal,
  [NEW_USER_MODAL_KEY]: NewUserModal,
  [EDIT_USER_MODAL_KEY]: EditUserModal,
  [EDIT_WORKSPACE_MODAL_KEY]: EditWorkspaceModal,
  [NEW_GROUP_MODAL_KEY]: NewGroupModal,
  [EDIT_GROUP_MODAL_KEY]: EditGroupModal,
  [NEW_TAG_MODAL_KEY]: NewTagModal,
  [EDIT_TAG_MODAL_KEY]: EditTagModal,
  [UPLOAD_PAGE_ATTACHMENT_MODAL_KEY]: UploadAttachmentModal,
};

declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof MODALS;
  }
}

export function ModalsProvider({ children }: PropsWithChildren) {
  return (
    <BaseModalsProvider modals={MODALS}>
      {children}
    </BaseModalsProvider>
  );
}
